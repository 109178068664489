import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class GiftUserService {
    getAll() {
        return http.get("/GiftUser",httpOptions);
    }
    get(id) {
        return http.get(`/GiftUser/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/GiftUser", data,httpOptions);
    }
    update(data) {
        return http.post(`/GiftUser/PutGiftUser`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/GiftUser/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/GiftUser/TrashGiftUser",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/GiftUser/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/GiftUser/RepeatGiftUser`, data,httpOptions);
    }
}
export default new GiftUserService();