<template>
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link">
        <img src="../assets/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">Spectra Baby</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img src="assets/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
            </div>
            <div class="info">
                <a href="#" class="d-block">{{ user_name }}</a>

            </div>
            <div class="form-groud" v-if="login">
                <button style="font-size: 14px;" @click.prevent="logOut" type="button" class="btn btn-block btn-outline-warning">Đăng Xuất</button>
            </div>

        </div>
        <!-- Sidebar user panel (optional) -->

        <!-- SidebarSearch Form -->
        <div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                <li class="nav-item" style="margin-bottom: 10px;">
                    <a href="#" class="nav-link active" style="background-color: #ee368a;width: 100%;color: #ffffff">
                        <router-link :to="{ name: 'Home' }">
                            <i class="fa-solid fa-house" style="padding: 0px 3px;"></i>
                            <p>
                                Trang chủ
                            </p>
                        </router-link>
                    </a>
                </li>
                <li class="nav-item" style="margin-bottom: 10px;">
                    <a href="#" class="nav-link active" style="width: 100%;">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            Spectra
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Các trang Seo
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'homeseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Home User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'contactseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Contact User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'productseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Sản phẩm User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'newseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tin tức User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'recruitmentseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tuyển dụng User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'agencyseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Đại lý Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'warrantyseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Bảo hành Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fa-solid fa-users"></i>
                                <p>
                                    Tài khoản
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'account' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-user pr-2 pl-1"></i>
                                            <p>Tài khoản Admin</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'account_user' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-user pr-2 pl-1"></i>
                                            <p>Tài khoản khách hàng</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'routes' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-link pr-2 pl-1"></i>
                                    <p>Routes Link</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'banner' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-desktop pr-2 pl-1"></i>
                                    <p>Banner</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Sản phẩm
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'category' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'product' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'imageProduct' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách ảnh sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'gift' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Quà tặng sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'voucher' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Voucher</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'question' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Câu hỏi sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Màu sắc và kích thước
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'attribute' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tùy chọn màu sắc, kích thước</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'valueattribute' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Giá trị tùy chọn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'item' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Giá theo màu và kích thước</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Giới thiệu
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'welcome' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách giới thiệu danh mục</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'welcomedetail' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách giới thiệu</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'application' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách lợi ích của danh mục sản phẩm</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Đặc điểm nổi bật
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'characteristic' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Đặc điểm nổi bật danh mục</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'characterList' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Đặc điểm nổi bật</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Cẩm nang
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'categoryNews' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục Cẩm nang</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'newslist' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Cẩm nang</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'recrutement' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách tin tức tuyển dụng</p>
                                </a>
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Chính sách
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'policy' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục chính sách</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'policylist' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách chính sách</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dịch vụ
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'service' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'servicedetail' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'questionservice' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Câu hỏi dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fa-solid fa-shop"></i>
                                <p>
                                    Đại lý
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'location' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Địa chỉ đại lý</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Đơn hàng
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'order' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng SpectraBaby.com.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orderspectravn' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng Spectra.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orderspectraCT' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng Spectra.com.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'payment' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng VNPAY</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                    <router-link :to="{ name: 'orderdetail' }">
                        <a href="" class="nav-link">
                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                            <p>Đơn đặt hàng chi tiết</p>
                        </a>
                    </router-link>
                </li> -->
                        <li class="nav-item">
                            <router-link :to="{ name: 'userLanding' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                    <p>Khách hàng đăng làm ký Đại lý</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'contact' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                    <p>Khách hàng Contact</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fa-solid fa-gift"></i>
                                <p>
                                    Thông tin đăng ký nhận quà
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>

                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'giftInformation' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-address-book pr-2 pl-1"></i>
                                            <p>Thông tin khách hàng</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'questionprize' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-gifts pr-2 pl-1"></i>
                                            <p>Giải thưởng Ba/Mẹ nhận được</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'qualityAssessment' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                    <p>Khách hàng đánh giá dịch vụ sản phẩm</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'experienceDay' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                    <p>Khách hàng đăng ký trải nghiệm sản phẩm Chi nhánh HN</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'experienceDayHCM' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                    <p>Khách hàng đăng ký trải nghiệm sản phẩm Chi nhánh HCM</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'feedback' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                    <p>Đánh giá của khách hàng</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'seriproduct' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-wrench pr-2 pl-1"></i>
                                    <p>Seri Sản Phẩm xuất từ Đại lý</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'warranty' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-wrench pr-2 pl-1"></i>
                                    <p>Bảo hành</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'warrantyGold' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-wrench pr-2 pl-1"></i>
                                    <p>Bảo hành vàng Spectra</p>
                                </a>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" style="margin-bottom: 10px;">
                    <a href="#" class="nav-link active" style="width: 100%;">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            Adicon
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Các trang Seo
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'homeseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Home User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'contactseo' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Contact User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'productseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Sản phẩm User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'newseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tin tức User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'recruitmentseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tuyển dụng User Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'agencyseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Đại lý Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'warrantyseoAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Bảo hành Seo</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Tài khoản
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <!-- <li class="nav-item">
                                    <router-link :to="{ name: 'accountAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-user pr-2 pl-1"></i>
                                            <p>Tài khoản Admin</p>
                                        </a>
                                    </router-link>
                                </li> -->
                                <li class="nav-item">
                                    <router-link :to="{ name: 'account_userAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-user pr-2 pl-1"></i>
                                            <p>Tài khoản khách hàng</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'bannerAdicon' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-desktop pr-2 pl-1"></i>
                                    <p>Banner</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Sản phẩm
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'categoryAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'productAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'imageProductAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách ảnh sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'giftAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Quà tặng sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'voucherAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Voucher</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'questionAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Câu hỏi sản phẩm</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Màu sắc và kích thước
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'attributeAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Tùy chọn màu sắc, kích thước</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'valueattributeAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Giá trị tùy chọn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'itemAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Giá theo màu và kích thước</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Giới thiệu
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'welcomeAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách giới thiệu danh mục</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'welcomedetailAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách giới thiệu</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'applicationAdicon' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách lợi ích của danh mục sản phẩm</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Đặc điểm nổi bật
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'characteristicAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Đặc điểm nổi bật danh mục</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'characterListAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Đặc điểm nổi bật</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Cẩm nang
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'categoryNewsAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục Cẩm nang</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'newslistAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách Cẩm nang</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <router-link :to="{ name: 'recrutementAdicon' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-list pr-2 pl-1"></i>
                                    <p>Danh sách tin tức tuyển dụng</p>
                                </a>
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Chính sách
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'policyAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục chính sách</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'policylistAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách chính sách</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dịch vụ
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'serviceAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh mục dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'servicedetailAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Danh sách dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'questionserviceAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Câu hỏi dịch vụ</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Đại lý
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'locationAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-list pr-2 pl-1"></i>
                                            <p>Địa chỉ đại lý</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Đơn hàng
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" style="display: none;padding-left: 20px;">
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orderAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng SpectraBaby.com.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orderadiconvnAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng Spectra.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'orderadiconCTAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng Spectra.com.vn</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name: 'paymentAdicon' }">
                                        <a href="" class="nav-link">
                                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                                            <p>Đơn đặt hàng VNPAY</p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                    <router-link :to="{ name: 'orderdetail' }">
                        <a href="" class="nav-link">
                            <i class="fa-solid fa-cart-shopping pr-2 pl-1"></i>
                            <p>Đơn đặt hàng chi tiết</p>
                        </a>
                    </router-link>
                </li> -->
                        <li class="nav-item">
                            <router-link :to="{ name: 'userLandingAdicon' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                    <p>Khách hàng đăng làm ký Đại lý</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'contactAdicon' }">
                                <a href="" class="nav-link">
                                    <i class="fa-solid fa-address-card pr-2 pl-1"></i>
                                    <p>Khách hàng Contact</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'feedbackAdicon' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-comments-dollar pr-2 pl-1"></i>
                                    <p>Đánh giá của khách hàng</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'seriproductAdicon' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-wrench pr-2 pl-1"></i>
                                    <p>Seri Sản Phẩm xuất từ Đại lý</p>
                                </a>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'warrantyAdicon' }">
                                <a href="./index3.html" class="nav-link">
                                    <i class="fa-solid fa-wrench pr-2 pl-1"></i>
                                    <p>Bảo hành</p>
                                </a>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>
</template>

<style>
.main-sidebar {
    width: 270px;
}
</style>

<script>
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    setup() {
        let login = JSON.parse(sessionStorage.getItem("user"));
        let user_name = login.accountName;
        return {
            login,
            user_name
        }
    },
    methods: {
        logOut() {
            createToast({
                title: 'Đã đăng xuất thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: 'true',
                showCloseButton: 'false',
            })
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
            window.location.href = '/login';
        }
    }
};
</script>
