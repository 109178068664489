<template>
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">

            <div class="row layout-top-spacing" style="margin-left: 4px;">
                <div class="statbox widget box box-shadow" style="width: 99%;">
                    <div class="row" style="padding: 30px 0px;">

                        <div class="col-5" style="margin-left: 4%;">
                            <h3 class="text-center">Spectra</h3>
                            <div>
                                <Bar v-if="data" :data="data" :options="options" style="height: 500px;" />
                            </div>
                        </div>
                        <div class="col-5" style="margin-left: 4%;">
                            <h3 class="text-center">Adicon</h3>
                            <div>
                                <Bar v-if="data" :data="data" :options="options" style="height: 500px;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  END CONTENT AREA  -->
    <!-- END MAIN CONTAINER -->
</template>
<script>
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
// import * as chartConfig from '../chartConfig.js'
import axios from "axios";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export default {
    components: {
        Bar
    },
    data() {
        return {
            loaded: false,
            data: null,
            options: {
                responsive: true,
                maintainAspectRatio: false
            },

        }
    },
    
    async mounted() {
        console.log(this.data); 
        
        try {
            const userlist = await axios.get('https://spectrababy.com.vn/dataApi/api/Banner/get-data-chart')
            this.data = {
                labels: [
                    'Sản phẩm',
                    'Khách hàng bảo hành',
                    'Đơn hàng đặt thành công',
                    'Tài khoản admin',
                    'Tài khoản khách hàng'
                ],
                datasets: [
                    {
                        label: 'Số lượng',
                        backgroundColor: '#f87979',
                        data: userlist.data
                    }
                ]
            };
            // console.log(this.data.datasets.data);
        } catch (e) {
            console.error(e)
        }
    },
    computed: {
        total() {
            return this.totalBebit?.map(item => item.totalAmount).reduce((total, amount) => {return total + amount},0);
        },
        totalSuccess() {
            return this.totalOrderSuccess?.map(item => item.totalAmount).reduce((total, amount) => {return total + amount},0);
        }

    },
    methods:{
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        }
        
    },
    

}
</script>