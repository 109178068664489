<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa bảo hành</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="warrantys">
                <input type="hidden" v-model="warrantys.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên khách hàng</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="warrantys.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Email</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="warrantys.email" :class="{ error: emailError.status, success: emailSuccess.status }" />
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Số điện thoại</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="warrantys.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Địa chỉ</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="address" placeholder="" v-model="warrantys.address" :class="{ error: addressError.status, success: addressSuccess.status }">
                                <p class="text-error" v-if="addressError.status">{{ addressError.text }}</p>
                                <p class="success-text" v-if="addressSuccess.status">{{ addressSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productName" placeholder="" v-model="warrantys.productName" :class="{ error: productNameError.status, success: productNameSuccess.status }">
                                <p class="text-error" v-if="productNameError.status">{{ productNameError.text }}</p>
                                <p class="success-text" v-if="productNameSuccess.status">{{ productNameSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Seri sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productSeri" placeholder="" v-model="warrantys.productSeri" :class="{ error: productSeriError.status, success: productSeriSuccess.status }">
                                <p class="text-error" v-if="productSeriError.status">{{ productSeriError.text }}</p>
                                <p class="success-text" v-if="productSeriSuccess.status">{{ productSeriSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày mua sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="dateBuy" placeholder="" v-model="warrantys.dateBuy" :class="{ error: dateBuyError.status, success: dateBuySuccess.status }">
                                <p class="text-error" v-if="dateBuyError.status">{{ dateBuyError.text }}</p>
                                <p class="success-text" v-if="dateBuySuccess.status">{{ dateBuySuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Giá trị hợp đồng bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="gtriHĐ" placeholder="" v-model="warrantys.gtriHĐ" :class="{ error: gtriHĐError.status, success: gtriHĐSuccess.status }">
                                <p class="text-error" v-if="gtriHĐError.status">{{ gtriHĐError.text }}</p>
                                <p class="success-text" v-if="gtriHĐSuccess.status">{{ gtriHĐSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Phí dịch vụ bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="phiDVBH" placeholder="" v-model="warrantys.phiDVBH" :class="{ error: phiDVBHError.status, success: phiDVBHSuccess.status }">
                                <p class="text-error" v-if="phiDVBHError.status">{{ phiDVBHError.text }}</p>
                                <p class="success-text" v-if="phiDVBHSuccess.status">{{ phiDVBHSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày bắt đầu bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="startDate" placeholder="" v-model="warrantys.startDate" :class="{ error: startDateError.status, success: startDateSuccess.status }">
                                <p class="text-error" v-if="startDateError.status">{{ startDateError.text }}</p>
                                <p class="success-text" v-if="startDateSuccess.status">{{ startDateSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày hết hạn bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="modifiedDate" placeholder="" v-model="warrantys.modifiedDate" :class="{ error: modifiedDateError.status, success: modifiedDateSuccess.status }">
                                <p class="text-error" v-if="modifiedDateError.status">{{ modifiedDateError.text }}</p>
                                <p class="success-text" v-if="modifiedDateSuccess.status">{{ modifiedDateSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row p-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
                            <span class="text-danger"><i>Chỉ gửi mail xác nhận khi thông tin bảo hành chưa chính xác!</i></span>
                            <span class="text-danger"><i>(Chỉnh sửa thông tin chính xác rồi ấn gửi mail!)</i></span>
                            <div class="col-sm-10">
                                <button type="button" @click="onConfirm()" class="btn btn-info mt-3">Gửi mail xác nhận BH</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-2 col-sm-2 pt-0">Trạng thái</label>
                                <div class="col-xl-6 col-lg-6 col-sm-6">
                                    <select class="form-control  basic" v-if="warrantys.status == 0" v-model="warrantys.status" id="status">
                                        <option :selected="warrantys.status === 0" value="0" disabled>Chưa xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 1" value="1">Xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 2" value="2">
                                            Đã thanh toán hợp đồng</option>
                                        <option :selected="warrantys.status === 3" value="3">
                                            Hợp đồng đang hoạt động</option>
                                        <option :selected="warrantys.status === 4" value="4">
                                            Đã hết hạn hợp đồng
                                        </option>
                                    </select>

                                    <select class="form-control  basic" v-else-if="warrantys.status == 1" v-model="warrantys.status" id="status">
                                        <option :selected="warrantys.status === 0" value="0" disabled>Chưa xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 1" value="1">Xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 2" value="2">
                                            Đã thanh toán hợp đồng</option>
                                        <option :selected="warrantys.status === 3" value="3">
                                            Hợp đồng đang hoạt động</option>
                                        <option :selected="warrantys.status === 4" value="4">
                                            Đã hết hạn hợp đồng
                                        </option>
                                    </select>

                                    <select class="form-control  basic" v-else-if="warrantys.status == 2" v-model="warrantys.status" id="status">
                                        <option :selected="warrantys.status === 0" value="0" disabled>Chưa xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 1" value="1">Xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 2" value="2">
                                            Đã thanh toán hợp đồng</option>
                                        <option :selected="warrantys.status === 3" value="3">
                                            Hợp đồng đang hoạt động</option>
                                        <option :selected="warrantys.status === 4" value="4">
                                            Đã hết hạn hợp đồng
                                        </option>
                                    </select>
                                    <select class="form-control  basic" v-else-if="warrantys.status == 3" v-model="warrantys.status" id="status">
                                        <option :selected="warrantys.status === 0" value="0" disabled>Chưa xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 1" value="1">Xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 2" value="2">
                                            Đã thanh toán hợp đồng</option>
                                        <option :selected="warrantys.status === 3" value="3">
                                            Hợp đồng đang hoạt động</option>
                                        <option :selected="warrantys.status === 4" value="4">
                                            Đã hết hạn hợp đồng
                                        </option>
                                    </select>
                                    <select class="form-control  basic" v-else-if="warrantys.status == 4" v-model="warrantys.status" id="status">
                                        <option :selected="warrantys.status === 0" value="0" disabled>Chưa xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 1" value="1">Xác nhận hợp đồng
                                        </option>
                                        <option :selected="warrantys.status === 2" value="2">
                                            Đã thanh toán hợp đồng</option>
                                        <option :selected="warrantys.status === 3" value="3">
                                            Hợp đồng đang hoạt động</option>
                                        <option :selected="warrantys.status === 4" value="4">
                                            Đã hết hạn hợp đồng
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">File hợp đồng</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" name="file" ref="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10" data-toggle="modal" data-target="#modal-lg" style="cursor: pointer;">
                                <iframe :src="'https://spectrababy.com.vn/dataApi/images/' + warrantys.file" width="100%" height="600px" v-if="ishowImage == false"></iframe>
                                <iframe v-bind:src="url" style="width: 100%; height: 600px;" v-if="ishowImage == true"></iframe>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label class="col-form-label col-xl-12 col-sm-12 col-sm-12 pt-0">Lịch sử bảo hành</label>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Cập nhật lịch sử bảo hành</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-0" style="display: block;">
                                    <div class="form-group row mb-4 " style="justify-content: center; padding: 20px;">
                                        <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Nội dung bảo hành</label>
                                        <div class="col-xl-8 col-lg-8 col-sm-8">
                                            <input type="text" class="form-control" id="warrantyContent" placeholder="" v-model="warratylog.warrantyContent">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <table class="table table-striped table-bordered" id="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">STT</th>
                                            <th scope="col">Bảo hành</th>
                                            <th scope="col">Phí bảo hành cũ</th>
                                            <th scope="col">Phí bảo hành mới</th>
                                            <th scope="col">Ngày bảo hành</th>
                                            <th scope="col">Người cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in warrantyloglist" :key="item.id">
                                            <th scope="row">1</th>
                                            <td>{{ item.warrantyContent }}</td>
                                            <td>{{ item.oldValue }}</td>
                                            <td>{{ item.newValue }}</td>
                                            <td>{{ format_date(item.createdDate) }}</td>
                                            <td>{{ item.changedBy }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="modal-lg" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe :src="'https://spectrababy.com.vn/dataApi/images/' + warrantys.file" width="100%" height="600px"></iframe>
                            </div>
                            <div class="modal-footer justify-content-between">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Đóng</button>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Cập nhật thông tin</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import WarrantyGoldService from '@/services/WarrantyGoldService';
import WarrantyGoldLogService from '@/services/WarrantyGoldLogService';
import UploadService from "@/services/UploadService";
import moment from 'moment'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['warranty'],

    data() {

        return {
            warrantys: this.warranty,
            warrantyloglist: null,
            warratylog: {
                id: null,
                warrantyGoldId: this.warranty.id,
                changedBy: '',
                warrantyContent: '',
                oldValue: this.warranty.phiDVBH,
                newValue: '',
            },
            url: null,
            a: null,
            ishowImage: false,
            old: "https://spectrababy.com.vn/dataApi/images/" + this.warranty.image,
            currentImage: undefined,
            currentSerial: this.warranty.productSeri,
            message: "",
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            addressError: {
                text: "",
                status: false,
            },
            addressSuccess: {
                text: "",
                status: false,
            },
            productNameError: {
                text: "",
                status: false,
            },
            productNameSuccess: {
                text: "",
                status: false,
            },
            dateBuyError: {
                text: "",
                status: false,
            },
            dateBuySuccess: {
                text: "",
                status: false,
            },

            productSeriError: {
                text: "",
                status: false,
            },
            productSeriSuccess: {
                text: "",
                status: false,
            },
            startDateError: {
                text: "",
                status: false,
            },
            startDateSuccess: {
                text: "",
                status: false,
            },
            modifiedDateError: {
                text: "",
                status: false,
            },
            modifiedDateSuccess: {
                text: "",
                status: false,
            },
            gtriHĐError: {
                text: "",
                status: false,
            },
            gtriHĐSuccess: {
                text: "",
                status: false,
            },
            phiDVBHError: {
                text: "",
                status: false,
            },
            phiDVBHSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        WarrantyGoldService.getAll().then((res) => {
            this.a = res.data
        })
        WarrantyGoldLogService.getWarrantyGold(this.warranty.id)
            .then((res) => {
                this.warrantyloglist = res.data
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })
    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // this.warrantys.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },
        uploadNewFile() {
            if (this.currentImage) { // Kiểm tra xem có chỉnh sửa file ảnh không
                // Upload file mới
                UploadService.upload(this.currentImage)
                    .then((response) => {
                        this.message = response.data.message;

                        // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                        this.warrantys.file = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file

                        // Sau đó lưu thông tin sản phẩm vào database
                        return WarrantyGoldService.update(this.warrantys);
                    })
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            } else {
                // Nếu không chỉnh sửa file ảnh, chỉ cần cập nhật thông tin sản phẩm
                WarrantyGoldService.update(this.warrantys)
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }

            this.$emit("ShowData", this.warrantys);
        },
        createwarrantylog() {
            let login = JSON.parse(sessionStorage.getItem("user"));
            this.warratylog.changedBy = login.accountName;
            this.warratylog.newValue = this.warrantys.phiDVBH;
            WarrantyGoldLogService.create(this.warratylog)
                .then((res) => {
                    this.warratylog = res.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });
        },
        handleSuccessResponse(res) {
            // Thực hiện hành động khi thành công
            this.warrantys = res.data;
            this.$emit("ShowEditData", this.warrantys);

            // Gửi dữ liệu hình ảnh lên để hiển thị trong component cha (nếu cần)
            this.$emit("ShowData", this.warrantys);

            // Hiển thị thông báo thành công
            createToast({
                title: 'Chỉnh sửa thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },

        handleError(error) {
            console.log(error);
            this.message = "Đã xảy ra lỗi khi sửa thông tin sản phẩm.";
            createToast({
                title: 'Chỉnh sửa thất bại',
            }, {
                position: 'top-right',
                type: 'danger',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },
        onSubmitEditForm() {
            var seriSame = false;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                // Bỏ qua số seri hiện tại khi kiểm tra
                if (this.warrantys.productSeri !== this.currentSerial && this.warrantys.productSeri == element.productSeri) {
                    seriSame = true;
                    break;
                }
            }
            if (this.warrantys.productName.length == 0) {
                this.productNameError = {
                    text: "productName cannot be empty",
                    status: true
                };
                this.productNameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warrantys.productName.length > 0) {
                this.productNameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productNameError = {
                    text: "",
                    status: false
                }
            } else {
                this.productNameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.warrantys.productSeri.length == 0) {
                this.productSeriError = {
                    text: "productSeri cannot be empty",
                    status: true
                };
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (seriSame) {
                createToast({
                    title: 'Seri sản phẩm này đã được bảo hành',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
                this.productSeriError = {
                    text: "Seri sản phẩm này đã được bảo hành !",
                    status: true
                }
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warrantys.productSeri.length > 0) {
                this.productSeriSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productSeriError = {
                    text: "",
                    status: false
                }
            } else {
                this.productSeriSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.productNameSuccess.status == true && this.productSeriSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.currentImage) {
                        UploadService.checkFileExists(this.warrantys.image, 'images') // Thay 'images' với thư mục con thực tế
                            .then(response => {
                                if (response.data.exists) {
                                    // Nếu file tồn tại, xóa nó
                                    return UploadService.deleteFile(this.warrantys.image, 'images');
                                } else {
                                    // Nếu file không tồn tại, tiếp tục với upload file mới
                                    return Promise.resolve();
                                }
                            })
                            .finally(() => {
                                // Tiếp tục upload file mới
                                if (this.warratylog.warrantyContent != '') {
                                    this.createwarrantylog();
                                }
                                this.uploadNewFile();
                            });
                    } else {
                        // Nếu không có file cũ để kiểm tra, tiếp tục upload file mới
                        if (this.warratylog.warrantyContent != '') {
                            this.createwarrantylog();
                        }
                        this.uploadNewFile();
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onConfirm() {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                WarrantyGoldService.Mailconfirm(this.warrantys)
                    .then((res) => {
                        console.log(res);
                        createToast({
                            title: 'Đã gửi mail xác nhận BH tới khách hàng',
                        }, {
                            position: 'top-right',
                            type: 'success',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        console.log(error);
                        createToast({
                            title: 'Đã có lỗi',
                        }, {
                            position: 'top-right',
                            type: 'danger',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    })
                    .finally(() => {
                        //Perform action in always
                    });
            }
        }
    }

}
</script>
