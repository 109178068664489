<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới bảo hành</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên khách hàng</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="warranty.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Email</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="warranty.email" :class="{ error: emailError.status, success: emailSuccess.status }" />
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Số điện thoại</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="warranty.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productName" placeholder="" v-model="warranty.productName" :class="{ error: productNameError.status, success: productNameSuccess.status }">
                                <p class="text-error" v-if="productNameError.status">{{ productNameError.text }}</p>
                                <p class="success-text" v-if="productNameSuccess.status">{{ productNameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="storeCode" placeholder="" v-model="warranty.storeCode" :class="{ error: storeCodeError.status, success: storeCodeSuccess.status }">
                                <p class="text-error" v-if="storeCodeError.status">{{ storeCodeError.text }}</p>
                                <p class="success-text" v-if="storeCodeSuccess.status">{{ storeCodeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Seri sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productSeri" placeholder="" v-model="warranty.productSeri" :class="{ error: productSeriError.status, success: productSeriSuccess.status }">
                                <p class="text-error" v-if="productSeriError.status">{{ productSeriError.text }}</p>
                                <p class="success-text" v-if="productSeriSuccess.status">{{ productSeriSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày bắt đầu bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="date" class="form-control" id="startDate" placeholder="" v-model="warranty.startDate" :class="{ error: startDateError.status, success: startDateSuccess.status }">
                                <p class="text-error" v-if="startDateError.status">{{ startDateError.text }}</p>
                                <p class="success-text" v-if="startDateSuccess.status">{{ startDateSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày hết hạn bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="date" class="form-control" id="modifiedDate" placeholder="" v-model="warranty.modifiedDate" :class="{ error: modifiedDateError.status, success: modifiedDateSuccess.status }">
                                <p class="text-error" v-if="modifiedDateError.status">{{ modifiedDateError.text }}</p>
                                <p class="success-text" v-if="modifiedDateSuccess.status">{{ modifiedDateSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" ref="file" name="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img v-if="url" :src="url" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Ghi chú</label>
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <textarea name="" id="description" style="width: 100%;" cols="30" rows="10" v-model="warranty.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></textarea>
                        <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                        <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import WarrantyService from '@/services/WarrantyService';
import UploadService from "@/services/UploadService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {

            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            ID: null,
            warranty: {
                id: null,
                phone: "",
                name: "",
                email: "",
                productName: "",
                image: "",
                productSeri: "",
                description: "",
                modifiedDate: "",
                storeCode: "",
                status: true
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            productNameError: {
                text: "",
                status: false,
            },
            productNameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },

            productSeriError: {
                text: "",
                status: false,
            },
            productSeriSuccess: {
                text: "",
                status: false,
            },
            startDateError: {
                text: "",
                status: false,
            },
            startDateSuccess: {
                text: "",
                status: false,
            },
            modifiedDateError: {
                text: "",
                status: false,
            },
            modifiedDateSuccess: {
                text: "",
                status: false,
            },
            storeCodeError: {
                text: "",
                status: false,
            },
            storeCodeSuccess: {
                text: "",
                status: false,
            },

        }

    },
    mounted() {
        WarrantyService.getAll().then((res) => {
            this.a = res.data
        })
    },

    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        onSubmitForm() {
            var seriSame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.warranty.productSeri == element.productSeri) {
                    seriSame = element.productSeri;
                    break;
                }
            }

            if (this.warranty.productName.length == 0) {
                this.productNameError = {
                    text: "productName cannot be empty",
                    status: true
                };
                this.productNameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warranty.productName.length > 0) {
                this.productNameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productNameError = {
                    text: "",
                    status: false
                }
            } else {
                this.productNameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.warranty.productSeri.length == 0) {
                this.productSeriError = {
                    text: "productSeri cannot be empty",
                    status: true
                };
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warranty.productSeri == seriSame) {
                createToast({
                    title: 'Seri sản phẩm này đã được bảo hành',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
                this.productSeriError = {
                    text: "Seri sản phẩm này đã được bảo hành !",
                    status: true
                }
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warranty.productSeri.length > 0) {
                this.productSeriSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productSeriError = {
                    text: "",
                    status: false
                }
            } else {
                this.productSeriSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.productNameSuccess.status == true && this.productSeriSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            this.message = response.data.message;
                            // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                            this.warranty.image = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file

                            // Sau đó lưu thông tin sản phẩm vào database
                            return WarrantyService.create(this.warranty);
                        })
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.warranty.id = this.ID;
                            this.warranty.status = true;

                            console.log(this.warranty);
                            this.$emit("ShowData", this.warranty);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })

                            WarrantyService.sendMail(this.warranty)
                                .then(() => {
                                    console.log("Email gửi thành công");
                                })
                                .catch((err) => {
                                    console.error("Lỗi khi gửi email: ", err);
                                });
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                        });

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // this.warranty.image = this.$refs.file.files.item(0).name;
        },
    }

}
</script>
