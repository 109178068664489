import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class QualityAssessmentService {
    getAll() {
        return http.get("/QualityAssessment",httpOptions);
    }
    get(id) {
        return http.get(`/QualityAssessment/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/QualityAssessment", data,httpOptions);
    }
    update(data) {
        return http.post(`/QualityAssessment/PutQualityAssessment`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/QualityAssessment/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/QualityAssessment/TrashQualityAssessment",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/QualityAssessment/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/QualityAssessment/RepeatQualityAssessment`, data,httpOptions);
    }
}
export default new QualityAssessmentService();