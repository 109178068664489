<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới đường link</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="form-group row mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Đường dẫn path</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="path" placeholder="" v-model="routes.path" :class="{ error: pathError.status, success: pathSuccess.status }" />
                        <p class="text-error" v-if="pathError.status">{{ pathError.text }}</p>
                        <p class="success-text" v-if="pathSuccess.status">{{ pathSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên Components</label>
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <input type="text" class="form-control" id="component" placeholder="" v-model="routes.component" :class="{ error: componentError.status, success: componentSuccess.status }" />
                        <p class="text-error" v-if="componentError.status">{{ componentError.text }}</p>
                        <p class="success-text" v-if="componentSuccess.status">{{ componentSuccess.text }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import RoutesService from "@/services/RoutesService.js";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            ID: null,
            routes: {
                id: null,
                path: "",
                component: "",
                isActive: true
            },

            pathError: {
                text: "",
                status: false,
            },
            pathSuccess: {
                text: "",
                status: false,
            },
            componentError: {
                text: "",
                status: false,
            },
            componentSuccess: {
                text: "",
                status: false,
            }
        }

    },

    mounted() {
        RoutesService.getAll().then((res) => {
            this.a = res.data
        })
    },

    methods: {
        onSubmitForm() {
            let seriSame = null;

            // Kiểm tra xem component có bị trùng lặp hay không
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.routes.component === element.component) {
                    seriSame = element.component;
                    break;
                }
            }

            // Kiểm tra các trường hợp
            if (!this.routes.component || this.routes.component.trim().length === 0) {
                // Nếu component rỗng
                this.componentError = {
                    text: "Component không được để trống!",
                    status: true
                };
                this.componentSuccess = {
                    text: "",
                    status: false
                };
            } else if (seriSame) {
                // Nếu component bị trùng lặp
                createToast({
                    title: 'Đã tồn tại Component này!',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: true,
                    showCloseButton: false,
                });
                this.componentError = {
                    text: "Đã tồn tại Component này!",
                    status: true
                };
                this.componentSuccess = {
                    text: "",
                    status: false
                };
            } else {
                // Nếu component hợp lệ và không trùng
                this.componentSuccess = {
                    text: "Thành công!",
                    status: true
                };
                this.componentError = {
                    text: "",
                    status: false
                };
            }

            if (this.componentSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 2) {
                    RoutesService.create(this.routes)
                        .then((res) => {
                            //Perform Success Action
                            this.ID = res.data.id;
                            this.routes.id = this.ID;
                            this.routes.status = true;
                            this.$emit("ShowData", this.routes);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    createToast({
                        title: 'Thêm mới thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
    }

}
</script>
