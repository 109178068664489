<template>
<div class="card card-warning mt-3">
    <div class="card-header mb-3" style="height: 52px">
        <h5>Chỉnh sửa Sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="products" enctype="multipart/form-data">
                <input type="hidden" v-model="products.id">
                <div class="row">
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12"> -->
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="products.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tên sản
                                phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="products.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Số tháng bảo hành</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="number" class="form-control" id="warrantyMonth" placeholder="" v-model="products.warrantyMonth" :class="{ error: warrantyMonthError.status, success: warrantyMonthSuccess.status }">
                                <p class="text-error" v-if="warrantyMonthError.status">{{ warrantyMonthError.text }}</p>
                                <p class="success-text" v-if="warrantyMonthSuccess.status">{{ warrantyMonthSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-6 col-sm-6 col-sm-6 pt-0">Trạng thái</label>
                                <div class="col-xl-6 col-lg-6 col-sm-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="products.status" :value="true" :checked="products.status === true" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Còn hàng
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="products.status" :value="false" :checked="products.status === false || products.status === ''" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Hết hàng
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-6 col-sm-6 col-sm-6 pt-0">Có màu sắc hoặc kích thước không?</label>
                                <div class="col-xl-6 col-lg-6 col-sm-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="products.option" :value="true" :checked="products.option === true" id="option" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Có
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="products.option" :value="false" :checked="products.option === false || products.option === ''" id="option" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Không
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                                <div class="col-4" style="margin-left: 20px;">
                                    <p class="btn btn-success btn-sm" @click="$refs.mainFile.click()">
                                        Choose file
                                    </p>
                                </div>
                                <div class="col-8">
                                    <label class="btn btn-default p-0">
                                        <input type="file" accept="image/*" name="file[]" multiple="multiple" ref="mainFile" @change="selectImage" :hidden="true" />
                                    </label>
                                </div>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <img :src="'https://spectrababy.com.vn/dataApi/images/' + products.images" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" v-if="ishowImage == false" />
                                    <img v-bind:src="url" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" v-if="ishowImage == true" />
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ảnh liên quan</label>
                            <img v-for="item in imagepro" :src="'https://spectrababy.com.vn/dataApi/images/' + item.imageName" :key="item.id" alt="" width="100">
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label col-xl-6 col-sm-3 col-sm-2 pt-0">Thêm ảnh liên quan</label>
                                <div class="col-8">
                                    <label class="btn btn-default p-0">
                                        <input type="file" accept="image/*" name="file" multiple="multiple" @change="selectImageFile($event)" />
                                    </label>
                                </div>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <img v-if="url1" :src="url1" style="width: 100px; height: 100px;" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-body" v-for="(items, i) in item" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                    <p>Tên ảnh liên quan: {{ items.imageName }}</p>
                                    <button type="button" class="btn btn-danger" @click="removeItem(i)">Xóa</button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4">
                                    <button type="button" class="btn btn-info mt-3" @click="addItem">Thêm tạm thời</button>
                                </div>
                                <div class="col-lg-6">
                                    <button type="button" class="btn btn-primary mt-3" @click="addImage">Thêm ảnh</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-xl-6 col-lg-6 col-sm-6">

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <input type="number" class="form-control" id="price" placeholder="" v-model="products.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                                        <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                                        <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh
                                        mục</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control basic" name="" id="categoryId" v-model="products.categoryId">
                                            <option value="" disabled>Choose</option>
                                            <option v-for="item in categorys" :key="item.id" :selected="products.categoryId === item.id" v-bind:value="item.id">
                                                {{
                                                            item.name
                                                        }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Quà tặng</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control basic" name="" id="giftId" v-model="products.giftId" :disabled="products.scheduleStatus == true && products.jobId != '0'">
                                            <option value="" disabled>Choose</option>
                                            <option v-for="item in gifts" :key="item.id" :selected="products.giftId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá khuyến mại hiện tại</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <input type="number" class="form-control" id="salePrice" placeholder="" v-model="oldsalePrice" :class="{ error: salePriceError.status, success: salePriceSuccess.status }" disabled>
                                        <p class="text-error" v-if="salePriceError.status">{{ salePriceError.text }}</p>
                                        <p class="success-text" v-if="salePriceSuccess.status">{{ salePriceSuccess.text }}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="discountType" class="col-xl-12 col-sm-12 col-form-label">Loại giảm giá</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control" id="discountType" v-model="discountType" :disabled="products.scheduleStatus == true && products.jobId != '0'">
                                            <option value="amount">Số tiền</option>
                                            <option value="percentage">Phần trăm</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="discountValue" class="col-xl-12 col-sm-12 col-form-label">Giá khuyến mại hoặc phần trăm</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <input type="number" :disabled="products.scheduleStatus == true && products.jobId != '0'" class="form-control" id="discountValue" placeholder="" v-model="discountValue" :class="{ error: salePriceError.status, success: salePriceSuccess.status }">
                                        <p class="text-error" v-if="salePriceError.status">{{ salePriceError.text }}</p>
                                        <p class="success-text" v-if="salePriceSuccess.status">{{ salePriceSuccess.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-lg-6" style="display: flex;">
                                <div class="col-lg-12" style="padding: 0;">
                                    <div class="form-group">
                                        <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh
                                            mục</label>
                                        <div class="col-xl-12 col-lg-12 col-sm-12">
                                            <select class="form-control basic" name="" id="categoryId" v-model="products.categoryId">
                                                <option value="">Choose</option>
                                                <option v-for="item in categorys" :key="item.id" :selected="products.categoryId === item.id" v-bind:value="item.id">
                                                    {{
                                                            item.name
                                                        }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Quà tặng</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control basic" name="" id="giftId" v-model="products.giftId">
                                            <option value="">Choose</option>
                                            <option v-for="item in gifts" :key="item.id" :selected="products.giftId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col=lg-12">
                                <div class="card card-primary card-outline card-tabs">
                                    <div class="card-header p-0 pt-1 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link voucheradd active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">Đặt lịch chương trình giảm giá & quà tặng</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link voucheradd" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">Đặt lịch voucher</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body" style="padding: 20px;padding-left: 0; padding-right: 0; padding-bottom: 0;">
                                        <div class="tab-content" id="custom-tabs-three-tabContent">
                                            <div class="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                                <div class="col-lg-12">
                                                    <div class="row" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding: 20px 0px; margin-bottom: 25px;">
                                                        <div class="col-lg-12">
                                                            <p class="ml-2" style="color: red; font-weight: 600; font-size: 18px;">Đặt lịch dành cho chương trình giảm giá và quà tặng</p>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày bắt đầu:</label>
                                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                    <input type="datetime-local" class="form-control" id="start" placeholder="" v-model="products.start" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày kết thúc:</label>
                                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                    <input type="datetime-local" class="form-control" id="ends" placeholder="" v-model="products.ends" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-lg-12">
                                                            <div class="col-6 form-group">
                                                                <div class="col-12">
                                                                    <button type="button" v-if="products.scheduleStatus == false" @click="onUpdateforTime()" class="btn btn-primary mt-3">Đặt lịch chương trình</button>
                                                                    <button type="button" v-if="products.scheduleStatus == true" disabled class="btn btn-primary mt-3">Đặt lịch chương trình</button>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 form-group">
                                                                <div class="col-12">
                                                                    <button type="button" v-if="products.scheduleStatus == true && products.jobId != '0'" @click="onCancelUpdate()" class="btn btn-danger mt-3">Hủy đặt lịch</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12" v-if="itemPro.length > 0">
                                                            <div class="form-group row">
                                                                <label class="col-form-label col-xl-6 col-sm-3 col-sm-2 pt-0" style="color: red">Màu sắc hoặc kích thước</label>
                                                                <div class="row card-body pt-0">
                                                                    <div class="atribute col-md-6" v-for="(item, i) in itemPro" :key="i" style="border-bottom: 1px solid;padding: 15px 0px;border-right: 1px solid;padding-left: 10px;">
                                                                        <p>Hậu tố: {{ item.attributeName }} : {{ item.valueAttributeName }}</p>
                                                                        <div class="row">
                                                                            <div class="col-md-6 d-flex">
                                                                                <label class="col-form-label mr-2">Giá: </label>
                                                                                <input type="text" class="form-control" id="price" placeholder="" v-model="item.price" :disabled="!item.editing">
                                                                            </div>
                                                                            <div class="col-xl-10 col-lg-10 col-sm-10 mb-2">
                                                                                <label class="col-form-label mr-2">Quà tặng: </label>
                                                                                <select class="form-control basic" name="" id="giftId" v-model="item.giftId" :disabled="!item.editing || item.jobId != '0'">
                                                                                    <option value="">Choose</option>
                                                                                    <option v-for="itemz in gifts" :key="itemz.id" :selected="item.giftId === itemz.id" v-bind:value="itemz.id">{{ itemz.name }}</option>
                                                                                </select>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <button type="button" class="btn" :class="item.editing ? 'btn-success' : 'btn-warning'" @click="toggleEdit(i)">
                                                                                    {{ item.editing ? 'Lưu' : 'Sửa' }}
                                                                                </button>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <button type="button" class="btn btn-info" @click="addItemToArray(item)">
                                                                                    +
                                                                                </button>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <button type="button" v-if="item.jobId != '0'" @click="getbyIdItem(item.id)" class="btn btn-danger mt-3">Hủy đặt lịch</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 mt-2">
                                                                        <h5>Danh sách dự đặt lịch hậu tố: </h5>
                                                                    </div>
                                                                    <div class="col-lg-12 row mt-2">
                                                                        <div class="col-lg-5" v-for="(item, i) in itemattribute" :key="i" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;margin-right:10px;margin-bottom:10px;padding: 10px;">
                                                                            <p>Hậu tố: {{item.valueAttributeName}}</p>
                                                                            <p>Giá: {{item.price}} VNĐ</p>
                                                                            <button type="button" class="btn btn-danger" @click="removeItemAttribute(i)">Xóa</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <button type="button" class="btn btn-info" @click="onUpdateItemdate()">
                                                                    Đặt lịch hậu tố
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                                <div class="col-lg-12">
                                                    <div class="row" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding: 20px 0px; margin-bottom: 25px;">
                                                        <div class="col-lg-12">
                                                            <p class="ml-2" style="color: red; font-weight: 600; font-size: 18px;">Đặt lịch dành cho voucher sản phẩm</p>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày bắt đầu:</label>
                                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                    <input type="datetime-local" class="form-control" id="start" placeholder="" v-model="vouchers.startDate" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày kết thúc:</label>
                                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                    <input type="datetime-local" class="form-control" id="ends" placeholder="" v-model="vouchers.endDate" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Voucher</label>
                                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                    <select class="form-control basic" name="" id="productId" @change="handleSelectChange">
                                                                        <option value="">Chọn</option>
                                                                        <option v-for="item in voucher" :key="item.id" v-bind:value="item.id">{{ item.voucherCode }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="card-body" v-for="(items, i) in itemvoucher" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                                                <p>Voucher: {{ items.voucherCode }}</p>
                                                                <button type="button" class="btn btn-danger" @click="removeItemVoucher(i)">Xóa</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="card-body" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                                                <h5 style="font-size: 18px;font-weight: 600;">
                                                                    Danh sách voucher đang áp dụng:
                                                                </h5>
                                                                <div v-for="(item , i) in voucheractive" :key="i">
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <p>{{item.voucherCode}}</p>
                                                                        </div>
                                                                        <div class="col-8" v-if="item.status == true && item.jobId != '0'">
                                                                            <button type="button" class="btn btn-danger" @click="getbyId(item.id)">Hủy áp dụng voucher</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-lg-12">
                                                            <div class="row col-12 form-group">
                                                                <div class="col-6">
                                                                    <button type="button" class="btn btn-primary mt-3" @click="onVoucher()">Đặt lịch</button>
                                                                </div>
                                                                <div class="col-6">
                                                                    <button type="button" class="btn btn-info mt-3" @click="addItemVoucher">Thêm danh sách chờ</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="products.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }">
                                <p class="text-error" v-if="titleSeoError.status">{{ titleSeoError.text }}</p>
                                <p class="success-text" v-if="titleSeoSuccess.status">{{ titleSeoSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Key Words</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="products.metaKeyWords" :class="{ error: metaKeyWordsError.status, success: metaKeyWordsSuccess.status }">
                                <p class="text-error" v-if="metaKeyWordsError.status">{{ metaKeyWordsError.text }}</p>
                                <p class="success-text" v-if="metaKeyWordsSuccess.status">{{ metaKeyWordsSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <textarea name="" id="metaDescription" style="width: 100%;" cols="30" rows="10" v-model="products.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }"></textarea>
                                <p class="text-error" v-if="metaDescriptionError.status">{{ metaDescriptionError.text }}
                                </p>
                                <p class="success-text" v-if="metaDescriptionSuccess.status">{{
                                        metaDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả nhỏ</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="products.titleDescription" :class="{ error: titleDescriptionError.status, success: titleDescriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="titleDescriptionError.status">{{ titleDescriptionError.text
                                    }}</p>
                                <p class="success-text" v-if="titleDescriptionSuccess.status">{{
                                        titleDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mô tả</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="products.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }">
                                </ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Hướng dẫn sử dụng</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="products.information" :class="{ error: descriptionError.status, success: descriptionSuccess.status }">
                                </ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Thông số kĩ thuật</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="products.instruct" :class="{ error: descriptionError.status, success: descriptionSuccess.status }">
                                </ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-10">
                            <button type="submit" class="btn btn-primary mt-3" style="width: 120px;">Xác nhận
                                sửa</button>
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </form>
        </div>
    </div>
</div>
</template>

<style>
.nav-link.voucheradd {
    font-weight: 700;
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.471);
}

.nav-link.voucheradd.active {
    color: #0053ff !important;
}
</style>

<script>
import ProductService from '@/services/ProductService';
import ImageProductService from '@/services/ImageProductService';
import CategoryProductService from '@/services/CategoryProductService';
import GiftService from '@/services/GiftService';
import VoucherService from '@/services/VoucherService';
import ItemService from '@/services/ItemService';
import UploadService from "@/services/UploadService";
import {
    ClassicEditor
} from '@ckeditor/ckeditor5-editor-classic';

import {
    Alignment
} from '@ckeditor/ckeditor5-alignment'; // Importing the package.
import {
    Autoformat
} from '@ckeditor/ckeditor5-autoformat';
import {
    BlockQuote
} from '@ckeditor/ckeditor5-block-quote';
import {
    CloudServices
} from '@ckeditor/ckeditor5-cloud-services';
import {
    Essentials
} from '@ckeditor/ckeditor5-essentials';
import {
    Heading
} from '@ckeditor/ckeditor5-heading';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
} from '@ckeditor/ckeditor5-image';
import {
    Indent
} from '@ckeditor/ckeditor5-indent';
import {
    Link,
    LinkImage
} from '@ckeditor/ckeditor5-link';
import {
    List
} from '@ckeditor/ckeditor5-list';
import {
    MediaEmbed
} from '@ckeditor/ckeditor5-media-embed';
import {
    Paragraph
} from '@ckeditor/ckeditor5-paragraph';
import {
    PasteFromOffice
} from '@ckeditor/ckeditor5-paste-from-office';
import {
    Table,
    TableToolbar
} from '@ckeditor/ckeditor5-table';
import {
    TextTransformation
} from '@ckeditor/ckeditor5-typing';
import {
    SourceEditing
} from '@ckeditor/ckeditor5-source-editing';
import {
    GeneralHtmlSupport
} from '@ckeditor/ckeditor5-html-support';
import {
    CKFinder
} from '@ckeditor/ckeditor5-ckfinder';
import {
    UploadAdapter
} from '@ckeditor/ckeditor5-adapter-ckfinder';
import {
    Base64UploadAdapter,
    SimpleUploadAdapter
} from '@ckeditor/ckeditor5-upload';
import {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript
} from '@ckeditor/ckeditor5-basic-styles';
import {
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize
} from '@ckeditor/ckeditor5-font';
import {
    CodeBlock
} from '@ckeditor/ckeditor5-code-block';
import {
    Highlight
} from '@ckeditor/ckeditor5-highlight';
import {
    HorizontalLine
} from '@ckeditor/ckeditor5-horizontal-line';
import {
    RemoveFormat
} from '@ckeditor/ckeditor5-remove-format';
import {
    PageBreak
} from '@ckeditor/ckeditor5-page-break';
import {
    Style
} from '@ckeditor/ckeditor5-style';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['product'],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    Heading,
                    Highlight,
                    CodeBlock,
                    BlockQuote,
                    CloudServices,
                    Essentials,
                    Bold,
                    Italic,
                    PageBreak,
                    Underline, Strikethrough, Subscript, Superscript,
                    FontColor, FontBackgroundColor, FontFamily, FontSize,
                    Link,
                    Style,
                    ImageInsert,
                    Paragraph,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    LinkImage,
                    Table,
                    TableToolbar,
                    SourceEditing,
                    RemoveFormat,
                    GeneralHtmlSupport,
                    CKFinder,
                    HorizontalLine,
                    Base64UploadAdapter,
                    SimpleUploadAdapter,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    UploadAdapter,
                    Indent,
                    List,
                    MediaEmbed,
                    PasteFromOffice,
                    TextTransformation
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'ckfinder',
                        'insertImage',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'alignment',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        'highlight',
                        'horizontalLine',
                        'pageBreak',
                        'removeFormat',
                        'strikethrough',
                        'underline',
                        'style',
                        'sourceEditing'
                    ]
                },
                ckfinder: {
                    uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
                    filebrowserBrowseUrl: '/ckfinder/browser',
                    filebrowserImageBrowseUrl: '/ckfinder/browser?type=Images',
                    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
                    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images'
                },
                heading: {
                    options: [{
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },

                language: 'vi',
                highlight: {
                    options: [{
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker'
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        // 'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:alignLeft',
                        'imageStyle:alignBlockLeft',
                        'imageStyle:alignBlockRight',
                        'toggleImageCaption',
                        'linkImage'
                    ]
                },
                style: {
                    definitions: [{
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                    ]
                },
                htmlSupport: {
                    allow: [{
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true
                    }]
                },
                // Configuration of the FontSize plugin.
                fontSize: {
                    options: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
                    ],
                    supportAllValues: true
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                fontFamily: {
                    options: [
                        'default',
                        'indieflowerregular',
                        'Arial, sans-serif',
                        'Verdana, sans-serif',
                        'Trebuchet MS',
                        'Apple Color Emoji',
                        'Segoe UI Emoji',
                        'Segoe UI Symbol',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [{
                        name: 'extraProvider',
                        url: /^example\.com\/media\/(\w+)/,

                    }, ]

                },
                licenseKey: ''
            },
            products: this.product,
            oldsalePrice: this.product.salePrice,
            categoryName: null,
            giftName: null,
            url: null,
            item: [],
            itemvoucher: [],
            itemattribute: [],
            ishowImage: false,
            categorys: null,
            gifts: null,
            url1: null,
            voucheractive: null,
            itemPro: [],
            vouchers: {
                startDate: '',
                endDate: '',
                id: '',
                voucherCode: '',
                discount: '',
                productId: '',
                jobId: '',
                status: true
            },
            voucher: null,
            voucherbyId: null,
            voucherdetail: null,
            itemdetail: null,
            imageproduct: {
                id: null,
                imageName: "",
                file: null,
                productId: this.product.id,
                status: true
            },

            imagepro: null,
            message: "",
            old: "localhost:51248/images/" + this.product.images,
            currentImage: null,
            discountType: 'amount', // Hoặc 'percentage'
            discountValue: '',
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            warrantyMonthError: {
                text: "",
                status: false,
            },
            warrantyMonthSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
            salePriceError: {
                text: "",
                status: false,
            },
            salePriceSuccess: {
                text: "",
                status: false,
            },
            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },
            categoryIdError: {
                text: "",
                status: false,
            },
            categoryIdSuccess: {
                text: "",
                status: false,
            },
            imagesError: {
                text: "",
                status: false,
            },
            imagesSuccess: {
                text: "",
                status: false,
            },

            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },

            metaKeyWordsError: {
                text: "",
                status: false,
            },
            metaKeyWordsSuccess: {
                text: "",
                status: false,
            },

            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
            titleDescriptionError: {
                text: "",
                status: false,
            },
            titleDescriptionSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        CategoryProductService.getAll()
            .then((res) => {
                this.categorys = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        ImageProductService.get(this.products.id)
            .then((res) => {
                this.imagepro = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        GiftService.getAll()
            .then((res) => {
                this.gifts = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        VoucherService.getVoucherPro(this.product.id)
            .then((res) => {
                this.voucher = res.data;
                console.log(this.voucher)
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        VoucherService.getVoucherProActive(this.product.id)
            .then((res) => {
                this.voucheractive = res.data;
                console.log(this.voucheractive)
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        ItemService.getpro(this.product.id)
            .then((res) => {
                this.itemPro = res.data.map(item => ({
                    ...item,
                    editing: false, // Thêm thuộc tính editing để theo dõi trạng thái chỉnh sửa
                    priceAgain: item.price
                }));
                console.log(this.itemPro);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    computed: {
        calculatedSalePrice() {
            if (this.discountType === 'amount') {
                return this.discountValue;
            } else if (this.discountType === 'percentage') {
                return this.products.price - (this.products.price * (this.discountValue / 100));
            }
            return this.products.price;
        }
    },
    watch: {
        calculatedSalePrice(newVal) {
            this.products.salePrice = newVal > 0 ? newVal : 0;
        }
    },
    methods: {
        addItemToArray(item) {
            const now = new Date();
            const datestart = new Date(this.products.start);
            const dateend = new Date(this.products.ends);

            // Check if fields are not empty
            if (!item.price || !this.products.start || !this.products.ends) {
                createToast({
                    title: 'Bạn không được để trống thông tin add voucher',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                });
                return; // Stop if there is an empty field
            }

            // Check if the start date is greater than the current time
            if (datestart <= now) {
                createToast({
                    title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                });
                return; // Stop if the start time is not valid
            }

            // Check if the end date is greater than the start date
            if (dateend <= datestart) {
                createToast({
                    title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                });
                return; // Stop if the end time is not valid
            }

            // If all conditions are valid, add startdate and enddate to the item
            item.startdate = this.products.start;
            item.enddate = this.products.ends;
            // Add the item to the array
            this.itemattribute.push(item);
            console.log('Selected Items:', this.itemattribute);
        },
        removeItemAttribute(i) {
            this.itemattribute.splice(i, 1);
        },
        getbyId(id) {
            VoucherService.get(id)
                .then((res) => {
                    this.voucherdetail = res.data;
                    this.onCancelUpdateVoucher(this.voucherdetail);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                })
        },
        getbyIdItem(id) {
            ItemService.get(id)
                .then((res) => {
                    this.itemdetail = res.data;
                    this.onCancelUpdateItem(this.itemdetail);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                })
        },
        handleSelectChange(event) {
            let id = event.target.value;
            VoucherService.get(id)
                .then((res) => {
                    this.voucherbyId = res.data;
                    this.vouchers = {
                        id: this.voucherbyId.id,
                        startDate: this.vouchers.startDate,
                        endDate: this.vouchers.endDate,
                        voucherCode: this.voucherbyId.voucherCode,
                        discountType: this.voucherbyId.discountType,
                        discount: this.voucherbyId.discount,
                        productId: this.voucherbyId.productId,
                        jobId: this.voucherbyId.jobId,
                        status: false,
                        scheduleStatus: true
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {})
        },
        addItemVoucher() {
            var date = new Date();
            var datestart = new Date(this.vouchers.startDate);
            var dateend = new Date(this.vouchers.endDate);
            if (this.vouchers.productId == '' || this.vouchers.startDate == '' || this.vouchers.endDate == '') {
                createToast({
                    title: 'Bạn không đc để trống thông tin add voucher',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else if (datestart < date) {

                createToast({
                    title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else if (dateend < datestart) {

                createToast({
                    title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var seriSame;
                for (let i = 0; i < this.itemvoucher.length; i++) {
                    const element = this.itemvoucher[i];
                    if (this.vouchers.voucherCode == element.voucherCode) {
                        seriSame = element.voucherCode;
                        break;
                    }
                }
                if (this.vouchers.voucherCode == seriSame) {
                    createToast({
                        title: 'Trùng lặp với danh sách voucher đã thêm',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.itemvoucher.push({
                        ...this.vouchers
                    });
                }
            }
        },
        removeItemVoucher(i) {
            this.itemvoucher.splice(i, 1);
        },
        addItem() {
            if (!this.imageproduct.imageName) {
                createToast({
                    title: 'Bạn không đc để trống',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: true,
                    showCloseButton: false,
                });
            } else {
                this.item.push({
                    ...this.imageproduct
                });
                console.log(this.item);
            }
        },
        removeItem(i) {
            this.item.splice(i, 1);
        },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        editorInput(e) {
            // console.log(e)
            // this.$emit('getEditorData', e);
            this.editorData = e;
        },

        selectImage() {
            this.currentImage = this.$refs.mainFile.files[0];
            console.log(this.currentImage)
            this.url = URL.createObjectURL(this.currentImage);
            // this.products.images = this.$refs.mainFile.files[0].name;
            this.ishowImage = true;
        },
        selectImageFile(ev) {
            const files = ev.target.files;
            this.imageproduct.file = files[0];
            this.url1 = URL.createObjectURL(files[0]);
            this.imageproduct.imageName = files[0].name;
        },
        uploadNewFile() {
            let login = JSON.parse(sessionStorage.getItem("user"));

            if (this.currentImage) { // Kiểm tra xem có chỉnh sửa file ảnh không
                // Upload file mới
                UploadService.upload(this.currentImage)
                    .then((response) => {
                        this.message = response.data.message;

                        // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                        this.products.images = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file
                        this.products.accountEdit = login.accountName;

                        // Sau đó lưu thông tin sản phẩm vào database
                        return ProductService.update(this.products);
                    })
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            } else {
                // Nếu không chỉnh sửa file ảnh, chỉ cần cập nhật thông tin sản phẩm
                this.products.accountEdit = login.accountName;
                ProductService.update(this.products)
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }

            this.$emit("ShowData", this.products);
        },

        handleSuccessResponse(res) {
            // Thực hiện hành động khi thành công
            this.products.categoryName = res.data.categoryName;
            this.$emit("ShowEditData", this.products);
            this.$emit("ShowData", this.products);
            createToast({
                title: 'Chỉnh sửa thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },

        handleError(error) {
            console.log(error);
            this.message = "Đã xảy ra lỗi khi sửa thông tin sản phẩm.";
            createToast({
                title: 'Chỉnh sửa thất bại',
            }, {
                position: 'top-right',
                type: 'danger',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },
        onSubmitEditForm() {
            if (this.products.code.length == 0) {
                this.codeError = {
                    text: "Code cannot be empty",
                    status: true
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.products.code.length < 4) {
                this.codeError = {
                    text: "Code must contain 4 characters",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.products.code.length >= 4) {
                this.codeSuccess = {
                    text: "Success!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.products.name.length == 0) {
                this.nameError = {
                    text: "NameProduct cannot be empty!",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.products.name.length < 6 || this.products.name.length > 250) {
                this.nameError = {
                    text: "NameProduct must be between 6 and 250 characters",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.products.name.length > 6 || this.products.name.length < 250) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.products.warrantyMonth.length == 0) {
                this.warrantyMonthError = {
                    text: "warrantyMonth cannot be empty",
                    status: true
                };
                this.warrantyMonthSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.products.warrantyMonth.length > 0) {
                this.warrantyMonthSuccess = {
                    text: "Success!",
                    status: true
                }
                this.warrantyMonthError = {
                    text: "",
                    status: false
                }
            } else {
                this.warrantyMonthSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.products.description.length == 0) {
                this.descriptionError = {
                    text: "Description cannot be empty",
                    status: true
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.products.description.length > 0) {
                this.descriptionSuccess = {
                    text: "Success!",
                    status: true
                }
                this.descriptionError = {
                    text: "",
                    status: false
                }
            } else {
                this.descriptionSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.products.salePrice.length == 0) {
                this.salePriceError = {
                    text: "SalePrice cannot be empty",
                    status: true
                };
                this.salePriceSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.products.salePrice.length > 0) {
                this.salePriceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.salePriceError = {
                    text: "",
                    status: false
                }
            } else {
                this.salePriceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.products.price.length == 0) {
                this.priceError = {
                    text: "Price cannot be empty",
                    status: true
                };
                this.priceSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.products.price.length > 0) {
                this.priceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.products.categoryId == 0) {
                this.categoryIdError = {
                    text: "Must chosse CategoryId!",
                    status: true
                };
                this.categoryIdSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.products.categoryId > 0) {
                this.categoryIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.categoryIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.categoryIdSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.products.images.length == 0) {
                this.imagesError = {
                    text: "Images cannot be empty!",
                    status: true
                };
                this.imagesSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.products.images.length > 0) {
                this.imagesSuccess = {
                    text: "Success!",
                    status: true
                }
                this.imagesError = {
                    text: "",
                    status: false
                }
            } else {
                this.imagesSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true && this.priceSuccess.status == true && this.imagesSuccess.status == true && this.warrantyMonthSuccess.status == true) {
                // Khai báo biến login ở đây
                let login = JSON.parse(sessionStorage.getItem("user"));

                if (login && (login.role == 1 || login.role == 2)) {
                    if (this.currentImage) {
                        UploadService.checkFileExists(this.products.images, 'images') // Thay 'images' với thư mục con thực tế
                            .then(response => {
                                if (response.data.exists) {
                                    // Nếu file tồn tại, xóa nó
                                    return UploadService.deleteFile(this.products.images, 'images');
                                } else {
                                    // Nếu file không tồn tại, tiếp tục với upload file mới
                                    return Promise.resolve();
                                }
                            })
                            .finally(() => {
                                // Tiếp tục upload file mới
                                this.uploadNewFile();
                            });
                    } else {
                        // Nếu không có file cũ để kiểm tra, tiếp tục upload file mới
                        this.uploadNewFile();
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    });
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                });
            }

        },
        addImage() {
            if (this.item.length >= 1) {
                for (let i = 0; i < this.item.length; i++) {
                    const element = this.item[i];
                    UploadService.upload(element.file)
                        .then((response) => {
                            console.log();
                            this.message = response.data.message;
                            element.imageName = response.data.savedFileNames[0];
                            return ImageProductService.create(element);
                        })
                        .then((res) => {
                            this.ID = res.data.id;
                            this.imageproduct.id = this.ID;
                            this.imageproduct.status = true;
                            this.imageproduct.productName = res.data.productName;

                            this.$emit("ShowData", this.imageproduct);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            });
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            element.file = undefined;
                            createToast({
                                title: 'Thêm mới thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            });
                        });
                }
            } else {
                UploadService.upload(this.imageproduct.file)
                    .then((response) => {
                        this.message = response.data.message;
                        this.imageproduct.imageName = response.data.savedFileNames[0];
                        return ImageProductService.create(this.imageproduct);
                    })
                    .then((res) => {
                        this.ID = res.data.id;
                        this.imageproduct.id = this.ID;
                        this.imageproduct.status = true;
                        this.imageproduct.productName = res.data.productName;

                        this.$emit("ShowData", this.imageproduct);
                        createToast({
                            title: 'Thêm mới thành công',
                        }, {
                            position: 'top-right',
                            type: 'success',
                            transition: 'zoom',
                            showIcon: true,
                            showCloseButton: false,
                        });
                    })
                    .catch((error) => {
                        this.message = "Unable to load image! " + error;
                        this.imageproduct.file = undefined;
                        createToast({
                            title: 'Thêm mới thất bại',
                        }, {
                            position: 'top-right',
                            type: 'danger',
                            transition: 'zoom',
                            showIcon: true,
                            showCloseButton: false,
                        });
                    });
            }
        },
        onUpdateforTime() {
            if ((this.products.start != null && this.products.ends != null) || (this.products.start != '0001-01-01 00:00:00.0000000' && this.products.ends != '0001-01-01 00:00:00.0000000')) {
                var date = new Date();
                var datestart = new Date(this.products.start);
                var dateend = new Date(this.products.ends);
                if (datestart < date) {
                    createToast({
                        title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else if (dateend < datestart) {
                    createToast({
                        title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    let login = JSON.parse(sessionStorage.getItem("user"));
                    this.products.accountEdit = login.accountName;
                    this.products.scheduleStatus = true;
                    ProductService.updatedate(this.products)
                        .then((res) => {
                            this.products = res.data;
                            this.$emit("ShowEditData", this.products);
                            createToast({
                                title: 'Đã đặt lịch',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            createToast({
                                title: 'Thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {

                        });

                    this.products.giftId = 1;
                    this.products.salePrice = 0;
                    ProductService.update(this.products)
                        .then((res) => {
                            console.log(res);
                            this.products.categoryName = res.data.categoryName;
                            this.$emit("ShowEditData", this.products);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });

                    this.$emit("ShowEditData", this.products);
                }

            } else {
                createToast({
                    title: 'Hãy chọn ngày tháng',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onCancelUpdate() {
            ProductService.cancelupdatedate(this.products)
                .then((res) => {
                    this.products = res.data;
                    this.$emit("ShowEditData", this.products);
                    createToast({
                        title: 'Bạn đã hủy đặt lịch',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                    setTimeout(() => {
                        location.reload();
                    }, 1500)
                })
                .catch((error) => {
                    console.log(error);
                    createToast({
                        title: 'Thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                })
                .finally(() => {

                });
        },

        onVoucher() {
            if ((this.vouchers.startDate != null && this.vouchers.endDate != null) || (this.vouchers.startDate != '0001-01-01 00:00:00.0000000' && this.vouchers.endDate != '0001-01-01 00:00:00.0000000')) {
                var date = new Date();
                var datestart = new Date(this.vouchers.startDate);
                var dateend = new Date(this.vouchers.endDate);
                if (datestart < date) {
                    createToast({
                        title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else if (dateend < datestart) {
                    createToast({
                        title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    if (this.itemvoucher.length >= 1) {
                        for (let i = 0; i < this.itemvoucher.length; i++) {
                            const element = this.itemvoucher[i];
                            VoucherService.updatedate(element)
                                .then((res) => {
                                    this.vouchers = res.data;
                                    this.$emit("ShowEditData", this.vouchers);
                                    createToast({
                                        title: 'Voucher đã được áp dụng',
                                    }, {
                                        position: 'top-right',
                                        type: 'success',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    createToast({
                                        title: 'Thất bại',
                                    }, {
                                        position: 'top-right',
                                        type: 'danger',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .finally(() => {

                                });

                            VoucherService.update(element)
                                .then((res) => {
                                    console.log(res);
                                    this.vouchers.productName = res.data.productName;
                                    this.$emit("ShowEditData", this.vouchers);
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                })
                                .finally(() => {
                                    //Perform action in always
                                });
                            createToast({
                                title: 'Đã đặt lịch voucher',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        }
                        this.$emit("ShowEditData", this.vouchers);
                    } else {
                        if (this.vouchers.productId == '' || this.vouchers.startDate == '' || this.vouchers.endDate == '') {
                            createToast({
                                title: 'Hãy điền đầy đủ thông tin',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        } else {
                            VoucherService.updatedate(this.vouchers)
                                .then((res) => {
                                    this.vouchers = res.data;
                                    this.$emit("ShowEditData", this.vouchers);
                                    createToast({
                                        title: 'Đã đặt lịch voucher',
                                    }, {
                                        position: 'top-right',
                                        type: 'success',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    createToast({
                                        title: 'Thất bại',
                                    }, {
                                        position: 'top-right',
                                        type: 'danger',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    })
                                })
                                .finally(() => {

                                });
                            VoucherService.update(this.vouchers)
                                .then((res) => {
                                    console.log(res);
                                    this.vouchers.productName = res.data.productName;
                                    this.$emit("ShowEditData", this.vouchers);
                                })
                                .catch((error) => {
                                    // error.response.status Check status code
                                    console.log(error);
                                })
                                .finally(() => {
                                    //Perform action in always
                                });
                            createToast({
                                title: 'Đã đặt lịch voucher',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                            this.$emit("ShowEditData", this.vouchers);
                        }
                    }
                    // this.vouchers.status = false;
                    // VoucherService.update(this.vouchers)
                    //     .then((res) => {
                    //         console.log(res);
                    //         this.$emit("ShowEditData", this.vouchers);
                    //     })
                    //     .catch((error) => {
                    //         // error.response.status Check status code
                    //         console.log(error);
                    //     })
                    //     .finally(() => {
                    //         //Perform action in always
                    //     });
                }

            } else {
                createToast({
                    title: 'Hãy chọn ngày tháng',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onUpdateItemdate() {
            if (this.itemattribute.length >= 1) {
                for (let i = 0; i < this.itemattribute.length; i++) {
                    const element = this.itemattribute[i];
                    ItemService.updatedate(element, element.startdate, element.enddate)
                        .then((res) => {
                            this.itemattribute = res.data;
                            this.$emit("ShowEditData", this.products);
                            createToast({
                                title: 'Đã đặt lịch',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            createToast({
                                title: 'Thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .finally(() => {});
                    element.giftId = 1;
                    element.price = element.priceAgain;
                    ItemService.update(element)
                        .then((res) => {
                            console.log(res);
                            this.itemattribute = res.data;
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                }
            }
        },
        onCancelUpdateItem(datas) {
            ItemService.cancelupdatedate(datas)
                .then((res) => {
                    datas = res.data;
                    this.$emit("ShowEditData", datas);
                    createToast({
                        title: 'Bạn đã hủy đặt lịch',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                    setTimeout(() => {
                        location.reload();
                    }, 1500)
                })
                .catch((error) => {
                    console.log(error);
                    createToast({
                        title: 'Thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                })
                .finally(() => {

                });
        },
        onCancelUpdateVoucher(datas) {
            VoucherService.cancelupdatedate(datas)
                .then((res) => {
                    datas = res.data;
                    this.$emit("ShowEditData", datas);
                    createToast({
                        title: 'Bạn đã hủy đặt lịch',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                    setTimeout(() => {
                        location.reload();
                    }, 1500)
                })
                .catch((error) => {
                    console.log(error);
                    createToast({
                        title: 'Thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                })
                .finally(() => {

                });
        },
        toggleEdit(index) {
            if (this.itemPro[index].editing) {
                this.onupdateItem(index); // Gọi phương thức cập nhật khi nhấn nút "Lưu"
            } else {
                this.itemPro[index].editing = true; // Kích hoạt chỉnh sửa khi nhấn nút "Sửa"
            }
        },
        onupdateItem(index) {
            const itemToUpdate = this.itemPro[index];
            ItemService.update(itemToUpdate)
                .then((res) => {
                    this.$set(this.itemPro, index, {
                        ...res.data,
                        editing: false
                    }); // Tắt chỉnh sửa sau khi lưu
                    createToast({
                        title: 'Đã lưu',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    createToast({
                        title: 'Đã lưu',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    });
                })
                .finally(() => {
                    // Đảm bảo rằng trạng thái chỉnh sửa bị tắt ngay cả khi có lỗi
                    this.itemPro[index].editing = false;
                });
        }
    }

}
</script>
