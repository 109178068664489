import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class ExperienceDayService {
    getAll() {
        return http.get("/ExperienceDay",httpOptions);
    }
    getAllHCM() {
        return http.get("/ExperienceDay/HCM",httpOptions);
    }
    get(id) {
        return http.get(`/ExperienceDay/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/ExperienceDay", data,httpOptions);
    }
    update(data) {
        return http.post(`/ExperienceDay/PutExperienceDay`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/ExperienceDay/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/ExperienceDay/TrashExperienceDay",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/ExperienceDay/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/ExperienceDay/RepeatExperienceDay`, data,httpOptions);
    }
}
export default new ExperienceDayService();