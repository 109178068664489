import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class ItemService {
    getAll() {
        return http.get("/Item",httpOptions);
    }
    get(id) {
        return http.get(`/Item/${id}`,httpOptions);
    }
    updatedate(data, start, end) {
        return http.post(`/Item/ItemHangfire?start=${start}&end=${end}`, data,httpOptions);
    }
    cancelupdatedate(data) {
        return http.post(`/Item/ItemHangfireCancel`, data,httpOptions);
    }
    getpro(id){
        return http.get(`/Item/getItemPro/${id}`,httpOptions);
    }
    getproOrder(id){
        return http.get(`/Item/getproduct/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/Item", data,httpOptions);
    }
    update(data) {
        return http.post(`/Item/PutItem`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/Item/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new ItemService();