import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class WarrantyGoldLogService {
    getAll() {
        return http.get("/WarrantyGoldLog",httpOptions);
    }
    get(id) {
        return http.get(`/WarrantyGoldLog/${id}`,httpOptions);
    }
    getWarrantyGold(id) {
        return http.get(`/WarrantyGoldLog/getwarrantygold/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/WarrantyGoldLog", data,httpOptions);
    } 
    update(data) {
        return http.post(`/WarrantyGoldLog/PutWarrantyGoldLog`, data,httpOptions);
    }
    delete(id) {
            return http.delete(`/WarrantyGoldLog/${id}`,httpOptions);
        }
        //   deleteAll() {
        //     return http.delete(`/tutorials`);
        //   }
        //   findByTitle(title) {
        //     return http.get(`/tutorials?title=${title}`);
        //   }
}
export default new WarrantyGoldLogService();