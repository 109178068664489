<template>
<div class="card-header">
    <h5>Quản lý Đơn hàng khách vãng lai</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">

        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">

                <div class="statbox widget box box-shadow" v-if="isShowEdit == false">
                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Tìm Kiếm</span>
                                </div>
                                <input type="text" v-model="query" class="form-control" placeholder="Tìm Kiếm">
                            </div>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">Mã đơn hàng</th>
                                        <th>Tên khách hàng</th>
                                        <th>Ngày đặt hàng</th>
                                        <th>Trạng thái</th>
                                        <th>Tổng giá</th>
                                        <th class="text-center">Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody v-if="query">
                                    <tr v-for="item in orderFilte" :key="item.iD">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ format_date(item.createdDate) }}</td>
                                        <td>
                                            <span class="badge badge-warning" v-if="item.status == 0">Pending</span>
                                            <span class="badge badge-info" v-if="item.status == 1">Approved</span>
                                            <span class="badge badge-primary" v-if="item.status == 2">Delivering</span>
                                            <span class="badge badge-success" v-if="item.status == 3">Successful
                                                Delivery</span>
                                            <span class="badge badge-danger" v-if="item.status == 4">Cancelled</span>
                                            <span class="badge badge-dark" v-if="item.status == 5">Returns</span>
                                            <span class="badge badge-warning" v-if="item.status == 6"> Payment approval</span>
                                        </td>
                                        <td>${{ formatPrice(item.totalAmount) }}</td>
                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" style="padding: 20px;" v-on:click="onEdit(item)"><img src="../../../../public/assets/img/icons8-search.gif" alt="" style="width: 30px;"></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="item in paginated" :key="item.id">
                                        <td class="text-center">{{ item.code }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ format_date(item.createdDate) }}</td>
                                        <td>
                                            <span class="badge badge-warning" v-if="item.status == 0">Pending</span>
                                            <span class="badge badge-info" v-if="item.status == 1">Approved</span>
                                            <span class="badge badge-primary" v-if="item.status == 2">Delivering</span>
                                            <span class="badge badge-success" v-if="item.status == 3">Successful
                                                Delivery</span>
                                            <span class="badge badge-danger" v-if="item.status == 4">Cancelled</span>
                                            <span class="badge badge-dark" v-if="item.status == 5">Returns</span>
                                            <span class="badge badge-warning" v-if="item.status == 6"> Payment approval</span>
                                        </td>
                                        <td>{{ formatPrice(item.totalAmount) }} VNĐ</td>

                                        <td class="text-center">
                                            <a href="javascript:void(0);" data-toggle="tooltip"  data-placement="top" title="" data-original-title="Edit" style="padding: 20px;" v-on:click="onEdit(item)"><img src="../../../../public/assets/img/icons8-search.gif" alt="" style="width: 30px;"></a>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="column is-12">
                            <nav class="pagination is-right" role="navigation" aria-label="pagination">
                                <ul class="pagination pagination-sm m-0 float-right" v-if="totalPaginate > 1">
                                    <li class="page-item"><a class="page-link" @click="prev" href="javascript:void(0)">«</a></li>
                                    <li class="page-item" v-for="item in totalPaginate" :key="item">
                                        <span v-on:click.prevent="onCurrent(item)" v-bind:class="{ isActive: (item === current), 'text-dark': isActive == false }" class="btn-paginate pagination-link go-to has-text-orange page-link" style="border-radius: 0;margin: 0;" aria-label="Goto page 1">{{ item }}</span>
                                    </li>
                                    <li class="page-item"><a class="page-link" @click="next" href="javascript:void(0)">»</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <a href="" v-if="isShowEdit == true || isShowAdd == true || isShowTrash == true" v-on:click.prevent="back_to"><svg xmlns="http://www.w3.org/2000/svg" width="16" style="width: 32px;
                                height: 32px;" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg></a>
                <EditOrder :order="showEdit" v-if="isShowEdit == true" @ShowEditData="getEdit($event)" />
            </div>
        </div>
    </div>
</div>
</template>

<style>
.pagination-list {
    list-style: none;
    display: flex;
}

.btn-paginate {
    margin-inline: 5px;
    cursor: pointer;
    border-style: groove;
    border-radius: 100%;
    text-align: center;
    padding: 10%;
    color: #000;

}

.isActive {
    background-color: #80b500;
    color: #ffff;
}
</style>

<script>
import "vue-awesome-paginate/dist/style.css";
import EditOrder from "@/view/Spectra/OrderCustomer/edit.vue"
import OrderServicesCus from '@/services/OrderServicesCus';
import moment from 'moment'
import OrderDetailServiceCus from '@/services/OrderDetailServiceCus';
export default {
    name: "Index",
    components: {
        EditOrder
    },
    data() {
        return {
            order: null,
            showEdit: null,
            isShowEdit: false,
            query: "",
            current: 1,
            pageSize: 5,
            isActive: false
        }
    },

    created() {
        OrderServicesCus.getAll()
            .then((res) => {
                this.order = res.data;
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //Perform action in always
            })

    },

    computed: {
        resultCount() {
            return this.order && this.order.length
        },
        orderFilte() {
            if (this.query) {
                return this.order.filter((order) => {
                    return (
                        order.name
                        .toLowerCase()
                        .indexOf(this.query.toLowerCase()) != -1
                    )
                })
            } else {
                return this.order;
            }

        },
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        totalPaginate() {
            if ((this.resultCount % 5) == 0) {
                return Math.floor(this.resultCount / 5);
            } else {
                return Math.floor(this.resultCount / 5) + 1;
            }
        },
        paginated() {
            console.log(this.resultCount);
            if (this.resultCount > 5) {
                return this.order.slice(this.indexStart, this.indexEnd, this.totalPaginate);
            } else {
                return this.order;
            }
        }

    },

    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        onCurrent(item) {

            this.isActive = true
            return this.current = item;
        },

        prev() {
            this.current--;
            if (this.current == 0) {
                return this.current = 1;
            }
        },
        next() {
            this.current++;
            if (this.current > this.totalPaginate) {
                return this.current = this.totalPaginate;
            }
        },
        onEdit(data) {
            this.showEdit = data;
            this.isShowEdit = true
            console.log(data);
        },
        back_to() {
            this.isShowEdit = false
        },
        onTrash() {
            this.isShowTrash = true
        },
        getData(data) {
            this.order.push(data);
            console.log(data);
            this.isShowAdd = false;
            this.$forceUpdate();

        },
        getEdit(data) {
            for (let i = 0; i < this.order.length; i++) {
                if (this.order[i].id == data.id) {
                    this.order[i] = data;
                    this.$forceUpdate()
                    break;
                }
            }

            this.isShowEdit = false;
        },
        onDelete(item) {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (confirm("Are you sure you want to delete the product " + item.code)) {
                    OrderDetailServiceCus.delete(item.id).then((res) => {
                        console.log(res);
                        OrderServicesCus.delete(item.id)
                            .then(response => {
                                console.log(response);
                                this.order.splice(this.order.findIndex(e => e.id == item.id), 1).push(response.data);
                            })
                            .catch(function (error) {
                                console.log(error)
                            })
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {})

                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
            }
        }
    }

}
</script>
