<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới Voucher</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->
    <div class="col-lg-12 mt-3">
        <div class="card card-primary card-outline card-outline-tabs">
            <div class="card-header p-0 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link voucheradd active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Thêm mới</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link voucheradd" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Đặt lịch và thêm mới</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade active show" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                        <div class="card-body">
                            <div class="widget-content widget-content-area">
                                <form method="post" v-on:submit.prevent="oncreate">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã Voucher</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="text" class="form-control" id="voucherCode" placeholder="" v-model="voucher.voucherCode" :class="{ error: voucherCodeError.status, success: voucherCodeSuccess.status }">
                                                    <p class="text-error" v-if="voucherCodeError.status">{{ voucherCodeError.text }}</p>
                                                    <p class="success-text" v-if="voucherCodeSuccess.status">{{ voucherCodeSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="discountType" class="col-xl-12 col-sm-12 col-form-label">Loại giảm giá</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <select class="form-control" id="discountType" v-model="voucher.discountType">
                                                        <option value="amount">Số tiền</option>
                                                        <option value="percentage">Phần trăm</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Discount</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="number" class="form-control" id="discount" min="1" placeholder="" v-model="voucher.discount" :class="{ error: discountError.status, success: discountSuccess.status }">
                                                    <p class="text-error" v-if="discountError.status">{{ discountError.text }}</p>
                                                    <p class="success-text" v-if="discountSuccess.status">{{ discountSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <select class="form-control basic" name="" id="productId" v-model="voucher.productId" @change="updateProductName" :class="{ error: productIdError.status, success: productIdSuccess.status }">
                                                        <option value="">Chọn</option>
                                                        <option v-for="item in product" :key="item.id" v-bind:value="item.id">{{
                                                            item.name
                                                        }}</option>
                                                    </select>
                                                    <p class="text-error" v-if="productIdError.status">{{ productIdError.text }}</p>
                                                    <p class="success-text" v-if="productIdSuccess.status">{{ productIdSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Số lượng</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="number" class="form-control" id="quantity" min="1" placeholder="" v-model="voucher.quantity" :class="{ error: quantityError.status, success: quantitySuccess.status }">
                                                    <p class="text-error" v-if="quantityError.status">{{ quantityError.text }}</p>
                                                    <p class="success-text" v-if="quantitySuccess.status">{{ quantitySuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-12 form-group">
                                                <div>
                                                    <button type="button" class="btn btn-info mt-3" @click="addItemVoucher1">Thêm danh sách tạm thời</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="col-lg-12">
                                                <h5>Danh sách thêm tạm thời: </h5>
                                                <div class="card-body" v-for="(items, i) in itemvoucher1" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                                    <p>Voucher: {{ items.voucherCode }}</p>
                                                    <p>Product: {{ items.productName }}</p>
                                                    <p>Discount: {{ items.discount }}</p>
                                                    <button type="button" class="btn btn-danger" @click="removeItemVoucher1(i)">Xóa</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-10">
                                            <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                        <div class="card-body">
                            <div class="widget-content widget-content-area">
                                <form method="post" v-on:submit.prevent="onSubmitForm">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã Voucher</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="text" class="form-control" id="voucherCode" placeholder="" v-model="voucher.voucherCode" :class="{ error: voucherCodeError.status, success: voucherCodeSuccess.status }">
                                                    <p class="text-error" v-if="voucherCodeError.status">{{ voucherCodeError.text }}</p>
                                                    <p class="success-text" v-if="voucherCodeSuccess.status">{{ voucherCodeSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="discountType" class="col-xl-12 col-sm-12 col-form-label">Loại giảm giá</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <select class="form-control" id="discountType" v-model="voucher.discountType">
                                                        <option value="amount">Số tiền</option>
                                                        <option value="percentage">Phần trăm</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Discount</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="number" class="form-control" id="discount" min="1" placeholder="" v-model="voucher.discount" :class="{ error: discountError.status, success: discountSuccess.status }">
                                                    <p class="text-error" v-if="discountError.status">{{ discountError.text }}</p>
                                                    <p class="success-text" v-if="discountSuccess.status">{{ discountSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <select class="form-control basic" name="" id="productId" v-model="voucher.productId" @change="updateProductName" :class="{ error: productIdError.status, success: productIdSuccess.status }">
                                                        <option value="">Chọn</option>
                                                        <option v-for="item in product" :key="item.id" v-bind:value="item.id">{{
                                                            item.name
                                                        }}</option>
                                                    </select>
                                                    <p class="text-error" v-if="productIdError.status">{{ productIdError.text }}</p>
                                                    <p class="success-text" v-if="productIdSuccess.status">{{ productIdSuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Số lượng</label>
                                                <div class="col-xl-12 col-lg-12 col-sm-12">
                                                    <input type="number" class="form-control" id="quantity" min="1" placeholder="" v-model="voucher.quantity" :class="{ error: quantityError.status, success: quantitySuccess.status }">
                                                    <p class="text-error" v-if="quantityError.status">{{ quantityError.text }}</p>
                                                    <p class="success-text" v-if="quantitySuccess.status">{{ quantitySuccess.text }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="col-lg-12">
                                                <div class="row" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding: 20px 0px; margin-bottom: 25px;">
                                                    <div class="col-lg-12">
                                                        <p class="ml-2" style="color: red; font-weight: 600; font-size: 18px;">Đặt lịch voucher sản phẩm</p>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày bắt đầu:</label>
                                                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                <input type="datetime-local" class="form-control" id="start" placeholder="" v-model="voucher.startDate" />
                                                            </div>
                                                            <p class="text-error" v-if="startDateError.status">{{ startDateError.text }}</p>
                                                            <p class="success-text" v-if="startDateSuccess.status">{{ startDateSuccess.text }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Ngày kết thúc:</label>
                                                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                                                <input type="datetime-local" class="form-control" id="ends" placeholder="" v-model="voucher.endDate" />
                                                            </div>
                                                            <p class="text-error" v-if="endDateError.status">{{ endDateError.text }}</p>
                                                            <p class="success-text" v-if="endDateSuccess.status">{{ endDateSuccess.text }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <h5>Danh sách add và đặt lịch: </h5>
                                                        <div class="card-body" v-for="(items, i) in itemvoucher" :key="i" style="box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);">
                                                            <p>Voucher: {{ items.voucherCode }}</p>
                                                            <p>Product: {{ items.productName }}</p>
                                                            <p>Discount: {{ items.discount }}</p>
                                                            <button type="button" class="btn btn-danger" @click="removeItemVoucher(i)">Xóa</button>
                                                        </div>
                                                    </div>

                                                    <div class="row col-lg-12">
                                                        <div class="col-12 form-group">
                                                            <div>
                                                                <button type="button" class="btn btn-info mt-3" @click="addItemVoucher">Thêm danh sách chờ add và đặt lịch</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 form-group">
                                                            <div>
                                                                <button type="submit" class="btn btn-primary mt-3">Thêm mới và đặt lịch</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<style>
.nav-link.voucheradd {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.471);
}

.nav-link.voucheradd.active {
    color: #0053ff !important;
}
</style>

<script>
import VoucherService from '@/services/VoucherService';
import ProductService from '@/services/ProductService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            productName: null,
            ID: null,
            product: null,
            itemvoucher: [],
            itemvoucher1: [],
            vouchers: {
                startDate: '',
                endDate: '',
                id: '',
                voucherCode: '',
                discount: '',
                productId: '',
                status: true
            },
            voucher: {
                id: null,
                voucherCode: "",
                discount: null,
                startDate: null,
                quantity: null,
                endDate: null,
                productName: "",
                discountType: 'amount',
                productId: "",
                status: false,
                scheduleStatus: true
            },
            productIdError: {
                text: "",
                status: false,
            },
            productIdSuccess: {
                text: "",
                status: false,
            },
            discountError: {
                text: "",
                status: false,
            },
            discountSuccess: {
                text: "",
                status: false,
            },
            voucherCodeError: {
                text: "",
                status: false,
            },
            voucherCodeSuccess: {
                text: "",
                status: false,
            },
            startDateError: {
                text: "",
                status: false,
            },
            startDateSuccess: {
                text: "",
                status: false,
            },
            endDateError: {
                text: "",
                status: false,
            },
            endDateSuccess: {
                text: "",
                status: false,
            },
            quantityError: {
                text: "",
                status: false,
            },
            quantitySuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        VoucherService.getAll().then((res) => {
            this.a = res.data
        })
        ProductService.getAll()
            .then((res) => {
                this.product = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        updateProductName() {
            const selectedProduct = this.product.find(
                (item) => item.id === this.voucher.productId
            );
            if (selectedProduct) {
                this.voucher.productName = selectedProduct.name;
            }
        },
        addItemVoucher1() {
            if (this.voucher.productId == '' || this.voucher.voucherCode == '' || this.voucher.discount == '') {
                createToast({
                    title: 'Bạn không đc để trống thông tin add voucher',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var seriSame;
                var seriSame1;
                for (let i = 0; i < this.itemvoucher1.length; i++) {
                    const element = this.itemvoucher1[i];
                    if (this.voucher.voucherCode == element.voucherCode) {
                        seriSame = element.voucherCode;
                        break;
                    }
                }
                for (let i = 0; i < this.a.length; i++) {
                    const element = this.a[i];
                    if (this.voucher.voucherCode == element.voucherCode) {
                        seriSame1 = element.voucherCode;
                        break;
                    }
                }
                if (this.voucher.voucherCode == seriSame) {
                    createToast({
                        title: 'Trùng lặp với danh sách voucher đã thêm',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else if (this.voucher.voucherCode == seriSame1) {
                    createToast({
                        title: 'Trùng lặp với danh sách voucher đã thêm',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.itemvoucher1.push({
                        ...this.voucher
                    });
                }
                console.log(this.itemvoucher1)
            }
        },
        removeItemVoucher1(i) {
            this.itemvoucher1.splice(i, 1);
        },
        addItemVoucher() {
            var date = new Date();
            var datestart = new Date(this.voucher.startDate);
            var dateend = new Date(this.voucher.endDate);
            if (this.voucher.productId == '' || this.voucher.startDate == '' || this.voucher.endDate == '' || this.voucher.voucherCode == '' || this.voucher.discount == '') {
                createToast({
                    title: 'Bạn không đc để trống thông tin add voucher',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else if (datestart < date) {

                createToast({
                    title: 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })

            } else if (dateend < datestart) {

                createToast({
                    title: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else {
                var seriSame;
                var seriSame1;
                for (let i = 0; i < this.itemvoucher.length; i++) {
                    const element = this.itemvoucher[i];
                    if (this.voucher.voucherCode == element.voucherCode) {
                        seriSame = element.voucherCode;
                        break;
                    }
                }
                for (let i = 0; i < this.a.length; i++) {
                    const element = this.a[i];
                    if (this.voucher.voucherCode == element.voucherCode) {
                        seriSame1 = element.voucherCode;
                        break;
                    }
                }
                if (this.voucher.voucherCode == seriSame) {
                    createToast({
                        title: 'Trùng lặp với danh sách voucher đã thêm',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else if (this.voucher.voucherCode == seriSame1) {
                    createToast({
                        title: 'Trùng lặp với danh sách voucher đã thêm',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                } else {
                    this.itemvoucher.push({
                        ...this.voucher
                    });
                }
                console.log(this.itemvoucher)
            }
        },
        removeItemVoucher(i) {
            this.itemvoucher.splice(i, 1);
        },
        createAndScheduleVoucher(voucher) {
            VoucherService.create(voucher)
                .then((res) => {
                    this.voucher.id = res.data.id;
                    this.voucher.status = true;
                    this.voucher.productName = res.data.productName;
                    // Cập nhật lại voucher với id và các thông tin khác
                    voucher.id = res.data.id;
                    voucher.status = true;
                    voucher.productName = res.data.productName;

                    this.$emit("ShowData", this.voucher);
                    this.showToast('Thêm mới thành công', 'success');

                    setTimeout(() => {
                        this.updateVoucherDate(voucher);
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error);
                    this.showToast('Thêm mới thất bại', 'danger');
                });
        },
        updateVoucherDate(voucher) {
            VoucherService.updatedate(voucher)
                .then((res) => {
                    this.voucher = res.data;
                    this.$emit("ShowEditData", this.voucher);
                    this.showToast('Voucher đã được áp dụng', 'success');
                })
                .catch((error) => {
                    console.log(error);
                    this.showToast('Thất bại', 'danger');
                });
        },
        showToast(message, type) {
            createToast({
                title: message,
            }, {
                position: 'top-right',
                type: type,
                transition: 'zoom',
                showIcon: 'true',
                showCloseButton: 'false',
            });
        },
        oncreate() {
            var codesame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.voucher.voucherCode == element.voucherCode) {
                    codesame = element.voucherCode;
                    break;
                }
            }
            if (this.voucher.productId == '') {
                this.productIdError = {
                    text: "productId cannot be empty!",
                    status: true
                }
                this.productIdSuccess = {
                    text: "",
                    status: false
                }

            } else {
                this.productIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productIdError = {
                    text: "",
                    status: false
                }
            }

            if (this.voucher.voucherCode.length == 0) {
                this.voucherCodeError = {
                    text: "voucherCode cannot be empty!",
                    status: true
                }
                this.voucherCodeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.voucherCode == codesame) {
                this.voucherCodeError = {
                    text: "voucherCode cannot be empty!",
                    status: true
                }
                this.voucherCodeSuccess = {
                    text: "",
                    status: false
                }
                createToast({
                    title: 'Voucher đã tồn tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else if (this.voucher.voucherCode.length > 0) {
                this.voucherCodeSuccess = {
                    text: "Success!",
                    status: true
                }
                this.voucherCodeError = {
                    text: "",
                    status: false
                }
            } else {
                this.voucherCodeSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.voucher.discount == '' || this.voucher.discount == null) {
                this.discountError = {
                    text: "discount cannot be empty!",
                    status: true
                }
                this.discountSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.discount > 0) {
                this.discountSuccess = {
                    text: "Success!",
                    status: true
                }
                this.discountError = {
                    text: "",
                    status: false
                }
            } else {
                this.discountSuccess = {
                    text: "",
                    status: false
                }
                this.discountError = {
                    text: "Discount must be greater than zero!",
                    status: true
                }
            }

            if (this.voucher.quantity == '' || this.voucher.quantity == null) {
                this.quantityError = {
                    text: "quantity cannot be empty!",
                    status: true
                }
                this.quantitySuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.quantity > 0) {
                this.quantitySuccess = {
                    text: "Success!",
                    status: true
                }
                this.quantityError = {
                    text: "",
                    status: false
                }
            } else {
                this.quantitySuccess = {
                    text: "",
                    status: false
                }
                this.quantityError = {
                    text: "quantity must be greater than zero!",
                    status: true
                }
            }

            if (this.productIdSuccess.status == true && this.voucherCodeSuccess.status == true && this.discountSuccess.status == true && this.quantitySuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));

                if (login.role == 1 || login.role == 2) {
                    if (this.itemvoucher1 && this.itemvoucher1.length >= 1) {
                        this.itemvoucher1.forEach(element => {
                            element.startDate = '0001-01-01 00:00:00.0000000';
                            element.endDate = '0001-01-01 00:00:00.0000000';
                            element.scheduleStatus = false;
                            VoucherService.create(element)
                                .then((res) => {
                                    this.voucher.id = res.data.id;
                                    this.voucher.status = true;
                                    this.voucher.productName = res.data.productName;
                                    this.$emit("ShowData", this.voucher);
                                    this.showToast('Thêm mới thành công', 'success');
                                    setTimeout(() => {
                                        location.reload();
                                    }, 1000)
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.showToast('Thêm mới thất bại', 'danger');
                                });
                        });
                    } else {
                        this.voucher.startDate = '0001-01-01 00:00:00.0000000';
                        this.voucher.endDate = '0001-01-01 00:00:00.0000000';
                        this.voucher.scheduleStatus = false;
                        VoucherService.create(this.voucher)
                            .then((res) => {
                                this.voucher.id = res.data.id;
                                this.voucher.status = true;
                                this.voucher.productName = res.data.productName;
                                this.$emit("ShowData", this.voucher);
                                this.showToast('Thêm mới thành công', 'success');
                                setTimeout(() => {
                                    location.reload();
                                }, 1000)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.showToast('Thêm mới thất bại', 'danger');
                            });
                    }
                } else {
                    this.showToast('Bạn không có quyền sử dụng chức năng này', 'danger');
                }
            } else {
                this.showToast('Thêm mới thất bại', 'danger');
            }
        },
        onSubmitForm() {
            var codesame;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                if (this.voucher.voucherCode == element.voucherCode) {
                    codesame = element.voucherCode;
                    break;
                }
            }
            if (this.voucher.productId == '') {
                this.productIdError = {
                    text: "productId cannot be empty!",
                    status: true
                }
                this.productIdSuccess = {
                    text: "",
                    status: false
                }

            } else {
                this.productIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productIdError = {
                    text: "",
                    status: false
                }
            }

            if (this.voucher.voucherCode.length == 0) {
                this.voucherCodeError = {
                    text: "voucherCode cannot be empty!",
                    status: true
                }
                this.voucherCodeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.voucherCode == codesame) {
                this.voucherCodeError = {
                    text: "voucherCode cannot be empty!",
                    status: true
                }
                this.voucherCodeSuccess = {
                    text: "",
                    status: false
                }
                createToast({
                    title: 'Voucher đã tồn tại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            } else if (this.voucher.voucherCode.length > 0) {
                this.voucherCodeSuccess = {
                    text: "Success!",
                    status: true
                }
                this.voucherCodeError = {
                    text: "",
                    status: false
                }
            } else {
                this.voucherCodeSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.voucher.discount == '' || this.voucher.discount == null) {
                this.discountError = {
                    text: "discount cannot be empty!",
                    status: true
                }
                this.discountSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.discount > 0) {
                this.discountSuccess = {
                    text: "Success!",
                    status: true
                }
                this.discountError = {
                    text: "",
                    status: false
                }
            } else {
                this.discountSuccess = {
                    text: "",
                    status: false
                }
                this.discountError = {
                    text: "Discount must be greater than zero!",
                    status: true
                }
            }

            if (this.voucher.quantity == '' || this.voucher.quantity == null) {
                this.quantityError = {
                    text: "quantity cannot be empty!",
                    status: true
                }
                this.quantitySuccess = {
                    text: "",
                    status: false
                }

            } else if (this.voucher.quantity > 0) {
                this.quantitySuccess = {
                    text: "Success!",
                    status: true
                }
                this.quantityError = {
                    text: "",
                    status: false
                }
            } else {
                this.quantitySuccess = {
                    text: "",
                    status: false
                }
                this.quantityError = {
                    text: "quantity must be greater than zero!",
                    status: true
                }
            }

            if (this.voucher.startDate == null || this.voucher.startDate == '') {
                this.startDateError = {
                    text: "startDate cannot be empty!",
                    status: true
                }
                this.startDateSuccess = {
                    text: "",
                    status: false
                }

            } else {
                this.startDateSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.startDateError = {
                    text: "",
                    status: false
                }
            }

            if (this.voucher.endDate == null || this.voucher.endDate == '') {
                this.endDateError = {
                    text: "endDate cannot be empty!",
                    status: true
                }
                this.endDateSuccess = {
                    text: "",
                    status: false
                }

            } else {
                this.endDateSuccess = {
                    text: "Thành công!",
                    status: true
                }
                this.endDateError = {
                    text: "",
                    status: false
                }
            }

            if (this.productIdSuccess.status == true && this.voucherCodeSuccess.status == true && this.discountSuccess.status == true && this.quantitySuccess.status == true && this.startDateSuccess.status == true && this.endDateSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                let date = new Date();
                let datestart = new Date(this.voucher.startDate);
                let dateend = new Date(this.voucher.endDate);

                if (login.role == 1 || login.role == 2) {
                    if (this.itemvoucher && this.itemvoucher.length >= 1) {
                        this.itemvoucher.forEach(element => {
                            this.createAndScheduleVoucher(element);
                        });
                    } else {
                        if (datestart < date) {
                            this.showToast('Thời gian bắt đầu phải lớn hơn thời gian hiện tại', 'danger');
                        } else if (dateend < datestart) {
                            this.showToast('Thời gian kết thúc phải lớn hơn thời gian bắt đầu', 'danger');
                        } else {
                            this.createAndScheduleVoucher(this.voucher);
                        }
                    }
                } else {
                    this.showToast('Bạn không có quyền sử dụng chức năng này', 'danger');
                }
            } else {
                this.showToast('Thêm mới thất bại', 'danger');
            }
        },
    }

}
</script>
