import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class RoutesService {
    getAll() {
        return http.get("/Routesr",httpOptions);
    }
    get(id) {
        return http.get(`/Routesr/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/Routesr", data,httpOptions);
    }
    update(data) {
        return http.post(`/Routesr/PutRoutesr`, data,httpOptions);
    } 
    delete(id) {
        return http.delete(`/Routesr/${id}`,httpOptions);
    }
    getTrash() {
        return http.get("/Routesr/TrashRoutesr",httpOptions);
    }
    temporaryDelete(data) {
        return http.post(`/Routesr/TemporaryDelete`, data,httpOptions);
    }
    repeat(data) {
        return http.post(`/Routesr/RepeatRoutesr`, data,httpOptions);
    }
}
export default new RoutesService();