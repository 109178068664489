<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới ảnh sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="imageproduct.productId" :class="{ error: productIdError.status, success: productIdSuccess.status }">
                                    <option value="">Chọn</option>
                                    <option v-for="item in product" :key="item.id" :selected="imageproduct.productId === item.id" v-bind:value="item.id">{{
                                                            item.name
                                                        }}</option>
                                </select>
                                <p class="text-error" v-if="productIdError.status">{{ productIdError.text }}</p>
                                <p class="success-text" v-if="productIdSuccess.status">{{ productIdSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" ref="file" name="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img v-if="url" :src="url" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ImageProductService from '@/services/ImageProductService';
import ProductService from '@/services/ProductService';
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import UploadService from "@/services/UploadService";
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            a: null,
            url: null,
            productName: null,
            ID: null,
            product: null,
            imageproduct: {
                id: null,
                imageName: "",
                productId: "",
                productName: "",
                status: true
            },
            productIdError: {
                text: "",
                status: false,
            },
            productIdSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        ImageProductService.getAll().then((res) => {
            this.a = res.data
        })
        ProductService.getAll()
            .then((res) => {
                this.product = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        onSubmitForm() {
            if (this.imageproduct.productId.length == 0) {
                this.productIdError = {
                    text: "productId cannot be empty!",
                    status: true
                }
                this.productIdSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.imageproduct.productId.length > 0) {
                this.productIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.productIdSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.productIdSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role === 1 || login.role === 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            this.message = response.data.message;

                            // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                            this.imageproduct.imageName = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file

                            // Sau đó lưu thông tin sản phẩm vào database
                            return ImageProductService.create(this.imageproduct);
                        })
                        .then((res) => {
                            // Thực hiện hành động khi thành công
                            this.ID = res.data.id;
                            this.imageproduct.id = this.ID;
                            this.imageproduct.status = true;
                            this.imageproduct.productName = res.data.productName;

                            // Gửi dữ liệu hình ảnh lên để hiển thị trong component cha (nếu cần)
                            this.$emit("ShowData", this.imageproduct);

                            // Hiển thị thông báo thành công
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: true,
                                showCloseButton: false,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.message = "Đã xảy ra lỗi khi lưu thông tin sản phẩm.";
                            createToast({
                                title: 'Thêm mới thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: true,
                                showCloseButton: false,
                            });
                        });
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: true,
                        showCloseButton: false,
                    });
                }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }

        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // Không cần thiết đặt imageName ở đây, vì bạn sẽ cập nhật sau khi tải lên thành công
        },
    }

}
</script>
