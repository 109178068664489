import { createRouter, createWebHistory } from "vue-router";
import AccountAdmin from "./view/Spectra/AccountAdmin/index";
import Category from "./view/Spectra/Category/index";
import CategoryNews from "./view/Spectra/CategoryNews/index"
import CategoryPolicy from "./view/Spectra/CategoryPolicy/index"
import PolicyList from "./view/Spectra/PolicyDetail/index"
import NewsList from "./view/Spectra/NewDetail/index"
import UserLanding from "./view/Spectra/UserLanding/index"
import Banner from "./view/Spectra/Banner/index";
import AccountUser from "./view/Spectra/AccountUser/index";
import Product from "./view/Spectra/Product/index";
import ProductAdd from "./view/Spectra/Product/add";
import Login from "./view/login";
import Home from "./view/home";
import Feedback from "./view/Spectra/Feedback/index";
import ImageProduct from "./view/Spectra/ImageProduct/index";
import Application from "./view/Spectra/Application/index";
import Characteristic from "./view/Spectra/Characteristic/index";
import CharacterList from "./view/Spectra/CharacterList/index";
import Service from "./view/Spectra/Service/index";
import Question from "./view/Spectra/Question/index";
import QuestionService from "./view/Spectra/QuestionService/index";
import ServiceDetail from "./view/Spectra/ServiceDetail/index";
import Contact from "./view/Spectra/Contact/index";
import Welcome from "./view/Spectra/Welcome/index";
import WelcomeDetail from "./view/Spectra/WelcomeDetail/index";
import Recrutement from "./view/Spectra/Recrutement/index";
import HomeSeo from "./view/Spectra/HomeSeo/index";
import ContactSeo from "./view/Spectra/ContactSeo/index";
import ProductSeo from "./view/Spectra/ProductSeo/index";
import NewSeo from "./view/Spectra/NewSeo/index";
import RecrutementSeo from "./view/Spectra/RecrutementSeo/index";
import WarrantySeo from "./view/Spectra/WarrantySeo/index";
import Warranty from "./view/Spectra/Warranty/index";
import WarrantyGold from "./view/Spectra/WarrantyGold/index";
import City from "./view/Spectra/City/index";
import QuestionPrize from "./view/Spectra/QuestionPrize/index";
import Attribute from "./view/Spectra/Attributes/index";
import ValueAttribute from "./view/Spectra/ValueAttribute/index";
import Item from "./view/Spectra/Item/index";
import Country from "./view/Spectra/Country/index";
import Location from "./view/Spectra/Location/index";
import Gift from "./view/Spectra/Gift/index";
import GiftInformation from "./view/Spectra/GiftUser/index";
import Order from "./view/Spectra/Order/index";
import OrderSpectraVn from "./view/Spectra/OrderSpectraBrand/index";
import OrderSpectraCT from "./view/Spectra/OrderSpectraCanTho/index";
import OrderCustomer from "./view/Spectra/OrderCustomer/index.vue";
import AgencySeo from "./view/Spectra/AgencySeo/index";
import Payment from "./view/Spectra/Payment/index";
import SeriProduct from "./view/Spectra/SeriProduct/index";
import OrderDetail from "./view/Spectra/OrderDetail/index";
import Voucher from "./view/Spectra/Voucher/index";
import QualityAssessment from "./view/Spectra/QualityAssessment/index";
import ExperienceDay from "./view/Spectra/ExperienceDay/index";
import ExperienceDayHCM from "./view/Spectra/ExperienceDayHCM/index";
import Routes from "./view/Spectra/Routes/index";



import CategoryAdicon from "./view/Adicon/Category/index";
import CategoryNewsAdicon from "./view/Adicon/CategoryNews/index"
import CategoryPolicyAdicon from "./view/Adicon/CategoryPolicy/index"
import PolicyListAdicon from "./view/Adicon/PolicyDetail/index"
import NewsListAdicon from "./view/Adicon/NewDetail/index"
import UserLandingAdicon from "./view/Adicon/UserLanding/index"
import BannerAdicon from "./view/Adicon/Banner/index";
import AccountUserAdicon from "./view/Adicon/AccountUser/index";
import ProductAdicon from "./view/Adicon/Product/index";
import ProductAddAdicon from "./view/Adicon/Product/add";
import FeedbackAdicon from "./view/Adicon/Feedback/index";
import ImageProductAdicon from "./view/Adicon/ImageProduct/index";
import ApplicationAdicon from "./view/Adicon/Application/index";
import CharacteristicAdicon from "./view/Adicon/Characteristic/index";
import CharacterListAdicon from "./view/Adicon/CharacterList/index";
import ServiceAdicon from "./view/Adicon/Service/index";
import QuestionAdicon from "./view/Adicon/Question/index";
import QuestionServiceAdicon from "./view/Adicon/QuestionService/index";
import ServiceDetailAdicon from "./view/Adicon/ServiceDetail/index";
import ContactAdicon from "./view/Adicon/Contact/index";
import WelcomeAdicon from "./view/Adicon/Welcome/index";
import WelcomeDetailAdicon from "./view/Adicon/WelcomeDetail/index";
import RecrutementAdicon from "./view/Adicon/Recrutement/index";
import HomeSeoAdicon from "./view/Adicon/HomeSeo/index";
import ContactSeoAdicon from "./view/Adicon/ContactSeo/index";
import ProductSeoAdicon from "./view/Adicon/ProductSeo/index";
import NewSeoAdicon from "./view/Adicon/NewSeo/index";
import RecrutementSeoAdicon from "./view/Adicon/RecrutementSeo/index";
import WarrantySeoAdicon from "./view/Adicon/WarrantySeo/index";
import WarrantyAdicon from "./view/Adicon/Warranty/index";
import CityAdicon from "./view/Adicon/City/index";
import AttributeAdicon from "./view/Adicon/Attributes/index";
import ValueAttributeAdicon from "./view/Adicon/ValueAttribute/index";
import ItemAdicon from "./view/Adicon/Item/index";
import CountryAdicon from "./view/Adicon/Country/index";
import LocationAdicon from "./view/Adicon/Location/index";
import GiftAdicon from "./view/Adicon/Gift/index";
import OrderAdicon from "./view/Adicon/Order/index";
import OrderAdiconVnAdicon from "./view/Adicon/OrderAdiconBrand/index";
import OrderAdiconCTAdicon from "./view/Adicon/OrderAdiconCanTho/index";
import OrderCustomerAdicon from "./view/Adicon/OrderCustomer/index.vue";
import AgencySeoAdicon from "./view/Adicon/AgencySeo/index";
import PaymentAdicon from "./view/Adicon/Payment/index";
import SeriProductAdicon from "./view/Adicon/SeriProduct/index";
import OrderDetailAdicon from "./view/Adicon/OrderDetail/index";
import VoucherAdicon from "./view/Adicon/Voucher/index";
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,

    },
    {
        path: "/homeseo",
        name: "homeseo",
        component: HomeSeo,
    },
    {
        path: "/contactseo",
        name: "contactseo",
        component: ContactSeo,
    },
    {
        path: "/newseo",
        name: "newseo",
        component: NewSeo,
    },
    {
        path: "/productseo",
        name: "productseo",
        component: ProductSeo,
    },
    {
        path: "/recruitmentseo",
        name: "recruitmentseo",
        component: RecrutementSeo,
    },
    {
        path: "/agencyseo",
        name: "agencyseo",
        component: AgencySeo,
    },
    {
        path: "/warrantyseo",
        name: "warrantyseo",
        component: WarrantySeo,
    },
    {
        path: "/warranty",
        name: "warranty",
        component: Warranty,
    },
    {
        path: "/warrantyGold",
        name: "warrantyGold",
        component: WarrantyGold,
    },
    {
        path: "/questionprize",
        name: "questionprize",
        component: QuestionPrize,
    },
    {
        path: "/city",
        name: "city",
        component: City,
    },
    {
        path: "/country",
        name: "country",
        component: Country,
    },
    {
        path: "/attribute",
        name: "attribute",
        component: Attribute,
    },
    {
        path: "/location",
        name: "location",
        component: Location,
    },
    {
        path: "/valueattribute",
        name: "valueattribute",
        component: ValueAttribute,
    },
    {
        path: "/item",
        name: "item",
        component: Item,
    },
    {
        path: "/account-manager",
        name: "account",
        component: AccountAdmin,
    },
    {
        path: "/order",
        name: "order",
        component: Order,
    },
    {
        path: "/orderspectravn",
        name: "orderspectravn",
        component: OrderSpectraVn,
    },
    {
        path: "/orderspectraCT",
        name: "orderspectraCT",
        component: OrderSpectraCT,
    },
    {
        path: "/payment",
        name: "payment",
        component: Payment,
    },
    {
        path: "/proadd",
        name: "proadd",
        component: ProductAdd,
    },
    {
        path: "/categoryProduct",
        name: "category",
        component: Category,
    },
    {
        path: "/imageProduct",
        name: "imageProduct",
        component: ImageProduct,
    },
    {
        path: "/gift",
        name: "gift",
        component: Gift,
    },
    {
        path: "/giftInformation",
        name: "giftInformation",
        component: GiftInformation,
    },
    {
        path: "/application",
        name: "application",
        component: Application,
    },
    {
        path: "/characteristic",
        name: "characteristic",
        component: Characteristic,
    },
    {
        path: "/characterList",
        name: "characterList",
        component: CharacterList,
    },
    {
        path: "/categoryNews",
        name: "categoryNews",
        component: CategoryNews,
    },
    {
        path: "/service",
        name: "service",
        component: Service,
    },
    {
        path: "/questionservice",
        name: "questionservice",
        component: QuestionService,
    },
    {
        path: "/userLanding",
        name: "userLanding",
        component: UserLanding,
    },
    {
        path: "/policy",
        name: "policy",
        component: CategoryPolicy,
    },
    {
        path: "/newslist",
        name: "newslist",
        component: NewsList,
    },
    {
        path: "/policylist",
        name: "policylist",
        component: PolicyList,
    },
    {
        path: "/policylist",
        name: "policylist",
        component: PolicyList,
    },
    {
        path: "/banner",
        name: "banner",
        component: Banner,
    },
    {
        path: "/question",
        name: "question",
        component: Question,
    },
    {
        path: "/servicedetail",
        name: "servicedetail",
        component: ServiceDetail,
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
    },
    {
        path: "/account_user",
        name: "account_user",
        component: AccountUser,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/product-manager",
        name: "product",
        component: Product,
    },
    {
        path: "/feedback",
        name: "feedback",
        component: Feedback,
    }
    ,
    {
        path: "/welcome",
        name: "welcome",
        component: Welcome,
    }
    ,
    {
        path: "/welcomedetail",
        name: "welcomedetail",
        component: WelcomeDetail,
    }
    ,
    {
        path: "/recrutement",
        name: "recrutement",
        component: Recrutement,
    },
    {
        path: "/order-customer",
        name: "ordercustomer",
        component: OrderCustomer,
    },
    {
        path: "/seriproduct",
        name: "seriproduct",
        component: SeriProduct,
    },
    {
        path: "/orderdetail",
        name: "orderdetail",
        component: OrderDetail,
    },
    {
        path: "/voucher",
        name: "voucher",
        component: Voucher,
    },
    {
        path: "/qualityAssessment",
        name: "qualityAssessment",
        component: QualityAssessment,
    },
    {
        path: "/experienceDay",
        name: "experienceDay",
        component: ExperienceDay,
    },
    {
        path: "/experienceDayHCM",
        name: "experienceDayHCM",
        component: ExperienceDayHCM,
    },
    {
        path: "/routes",
        name: "routes",
        component: Routes,
    },




    {
        path: "/homeseoAdicon",
        name: "homeseoAdicon",
        component: HomeSeoAdicon,
    },
    {
        path: "/contactseoAdicon",
        name: "contactseoAdicon",
        component: ContactSeoAdicon,
    },
    {
        path: "/newseoAdicon",
        name: "newseoAdicon",
        component: NewSeoAdicon,
    },
    {
        path: "/productseoAdicon",
        name: "productseoAdicon",
        component: ProductSeoAdicon,
    },
    {
        path: "/recruitmentseoAdicon",
        name: "recruitmentseoAdicon",
        component: RecrutementSeoAdicon,
    },
    {
        path: "/agencyseoAdicon",
        name: "agencyseoAdicon",
        component: AgencySeoAdicon,
    },
    {
        path: "/warrantyseoAdicon",
        name: "warrantyseoAdicon",
        component: WarrantySeoAdicon,
    },
    {
        path: "/warrantyAdicon",
        name: "warrantyAdicon",
        component: WarrantyAdicon,
    },
    {
        path: "/cityAdicon",
        name: "cityAdicon",
        component: CityAdicon,
    },
    {
        path: "/countryAdicon",
        name: "countryAdicon",
        component: CountryAdicon,
    },
    {
        path: "/attributeAdicon",
        name: "attributeAdicon",
        component: AttributeAdicon,
    },
    {
        path: "/locationAdicon",
        name: "locationAdicon",
        component: LocationAdicon,
    },
    {
        path: "/valueattributeAdicon",
        name: "valueattributeAdicon",
        component: ValueAttributeAdicon,
    },
    {
        path: "/itemAdicon",
        name: "itemAdicon",
        component: ItemAdicon,
    },
    {
        path: "/orderAdicon",
        name: "orderAdicon",
        component: OrderAdicon,
    },
    {
        path: "/orderadiconvnAdicon",
        name: "orderadiconvnAdicon",
        component: OrderAdiconVnAdicon,
    },
    {
        path: "/orderadiconCTAdicon",
        name: "orderadiconCTAdicon",
        component: OrderAdiconCTAdicon,
    },
    {
        path: "/paymentAdicon",
        name: "paymentAdicon",
        component: PaymentAdicon,
    },
    {
        path: "/proaddAdicon",
        name: "proaddAdicon",
        component: ProductAddAdicon,
    },
    {
        path: "/categoryProductAdicon",
        name: "categoryAdicon",
        component: CategoryAdicon,
    },
    {
        path: "/imageProductAdicon",
        name: "imageProductAdicon",
        component: ImageProductAdicon,
    },
    {
        path: "/giftAdicon",
        name: "giftAdicon",
        component: GiftAdicon,
    },
    {
        path: "/applicationAdicon",
        name: "applicationAdicon",
        component: ApplicationAdicon,
    },
    {
        path: "/characteristicAdicon",
        name: "characteristicAdicon",
        component: CharacteristicAdicon,
    },
    {
        path: "/characterListAdicon",
        name: "characterListAdicon",
        component: CharacterListAdicon,
    },
    {
        path: "/categoryNewsAdicon",
        name: "categoryNewsAdicon",
        component: CategoryNewsAdicon,
    },
    {
        path: "/serviceAdicon",
        name: "serviceAdicon",
        component: ServiceAdicon,
    },
    {
        path: "/questionserviceAdicon",
        name: "questionserviceAdicon",
        component: QuestionServiceAdicon,
    },
    {
        path: "/userLandingAdicon",
        name: "userLandingAdicon",
        component: UserLandingAdicon,
    },
    {
        path: "/policyAdicon",
        name: "policyAdicon",
        component: CategoryPolicyAdicon,
    },
    {
        path: "/newslistAdicon",
        name: "newslistAdicon",
        component: NewsListAdicon,
    },
    {
        path: "/policylistAdicon",
        name: "policylistAdicon",
        component: PolicyListAdicon,
    },
    {
        path: "/policylistAdicon",
        name: "policylistAdicon",
        component: PolicyListAdicon,
    },
    {
        path: "/bannerAdicon",
        name: "bannerAdicon",
        component: BannerAdicon,
    },
    {
        path: "/questionAdicon",
        name: "questionAdicon",
        component: QuestionAdicon,
    },
    {
        path: "/servicedetailAdicon",
        name: "servicedetailAdicon",
        component: ServiceDetailAdicon,
    },
    {
        path: "/contactAdicon",
        name: "contactAdicon",
        component: ContactAdicon,
    },
    {
        path: "/account_userAdicon",
        name: "account_userAdicon",
        component: AccountUserAdicon,
    },
    {
        path: "/product-managerAdicon",
        name: "productAdicon",
        component: ProductAdicon,
    },
    {
        path: "/feedbackAdicon",
        name: "feedbackAdicon",
        component: FeedbackAdicon,
    }
    ,
    {
        path: "/welcomeAdicon",
        name: "welcomeAdicon",
        component: WelcomeAdicon,
    }
    ,
    {
        path: "/welcomedetailAdicon",
        name: "welcomedetailAdicon",
        component: WelcomeDetailAdicon,
    }
    ,
    {
        path: "/recrutementAdicon",
        name: "recrutementAdicon",
        component: RecrutementAdicon,
    },
    {
        path: "/order-customerAdicon",
        name: "ordercustomerAdicon",
        component: OrderCustomerAdicon,
    },
    {
        path: "/seriproductAdicon",
        name: "seriproductAdicon",
        component: SeriProductAdicon,
    },
    {
        path: "/orderdetailAdicon",
        name: "orderdetailAdicon",
        component: OrderDetailAdicon,
    },
    {
        path: "/voucherAdicon",
        name: "voucherAdicon",
        component: VoucherAdicon,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem("user");

    if (authRequired && !loggedIn) {
        return next({
            path: "/login",
            query: { returnUrl: to.path }
        });
    }
    next();
});
// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (store.getters.isLoggedIn) {
//             next()
//             return
//         }
//         next('/')
//     } else {
//         next()
//     }
// })
export default router;