<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa Đánh giá</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" enctype="multipart/form-data" v-on:submit.prevent="onSubmitEditForm" v-if="feedbacks">
                <input type="hidden" v-model="feedbacks.id" />
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mã :</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="feedbacks.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="codeError.status">
                                    <i>{{ codeError.text }}</i>
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="codeSuccess.status">
                                    {{ codeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tên Khách hàng:</label>
                            <div class="col-xl-10 col-lg-10 col-sm-10">
                                <input type="text" class="form-control" id="title" placeholder="" v-model="feedbacks.title" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-danger mb-0 mt-2" v-if="nameError.status">
                                    {{ nameError.text }}
                                </p>
                                <p class="text-success mb-0 mt-2" v-if="nameSuccess.status">
                                    {{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái :</label>
                                <div class="col-xl-10 col-lg-9 col-sm-10">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="feedbacks.status" :value="true" :checked="feedbacks.status === true" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Action
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="feedbacks.status" :value="false" :checked="feedbacks.status === false || feedbacks.status === ''" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            No Action
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <select class="form-control basic" name="" id="productId" v-model="feedbacks.productId">
                                    <option value="">Chọn</option>
                                    <option v-for="item in categorys" :key="item.id" :selected="feedbacks.productId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="form-group row mb-4">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh :</label>
                            <div class="col-4">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Choose file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" name="file" ref="file" @change="selectImage" multiple :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img :src="'https://spectrababy.com.vn/dataApi/images/' + feedbacks.image" style="width: 400px; height: 300px; margin-left: 20%" v-if="ishowImage == false" />
                                <img v-bind:src="url" style="width: 300px; height: 250px; margin-left: 20%" v-if="ishowImage == true" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả :</label>
                    <div class="col-xl-10 col-lg-10 col-sm-10">
                        <textarea name="" class="form-control" id="" cols="30" v-model="feedbacks.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }" rows="10"></textarea>
                        <p class="text-danger mb-0 mt-2" v-if="descriptionError.status">
                            {{ descriptionError.text }}
                        </p>
                        <p class="text-success mb-0 mt-2" v-if="descriptionSuccess.status">
                            {{ descriptionSuccess.text }}
                        </p>

                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import ProductService from "@/services/ProductService";
import FeedbackService from "@/services/FeedbackService";
import UploadService from "@/services/UploadService";
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ["feedback"],
    data() {
        return {
            message: "",
            currentImage: undefined,
            url: null,
            categorys: null,
            feedbacks: this.feedback,
            ishowImage: false,
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
        };
    },
    mounted() {
        ProductService.getAll()
            .then((res) => {
                this.categorys = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    methods: {
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            this.feedbacks.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },

        onSubmitEditForm() {

            if (this.feedbacks.code.length == 0) {
                this.codeError = {
                    text: "*Mã không được để trống",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedbacks.code.length < 4 || this.feedbacks.code.length > 6) {
                this.codeError = {
                    text: "*Mã phải từ 4 đến 6 kí tự",
                    status: true,
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedbacks.code.length > 4 || this.feedbacks.code.length < 6) {
                this.codeSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.codeError = {
                    text: "",
                    status: false,
                };
            } else {
                this.codeError = {
                    text: "",
                    status: false,
                };
            }

            if (this.feedback.title.length == 0) {
                this.nameError = {
                    text: "Name cannot be empty",
                    status: true,
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedback.title.length > 0) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true,
                };
                this.nameError = {
                    text: "",
                    status: false,
                };
            } else {
                this.nameError = {
                    text: "",
                    status: false,
                };
            }
            if (this.feedbacks.description.length == 0) {
                this.descriptionError = {
                    text: "Mô tả không được để trống",
                    status: true,
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.feedbacks.description.length > 6 || this.feedbacks.description.length < 50) {
                this.descriptionSuccess = {
                    text: "Thành công!",
                    status: true,
                };
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            } else {
                this.descriptionError = {
                    text: "",
                    status: false,
                };
            }
            if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.descriptionSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            console.log();
                            this.message = response.data.message;
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                        });
                    FeedbackService.update(this.feedbacks)
                        .then((res) => {
                            //Perform Success Action
                            this.feedbacks = res.data;
                            this.feedbacks.productName = res.data.productName;
                            this.$emit("ShowEditData", this.feedbacks);
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        })
                        .finally(() => {
                            //Perform action in always
                        });
                    this.$emit("ShowEditData", this.feedbacks);
                    createToast({
                        title: 'Chỉnh sửa thành công',
                    }, {
                        position: 'top-right',
                        type: 'success',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })

                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },

    },
};
</script>
