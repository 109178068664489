<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới sản phẩm</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <div class="row">
                    <!-- <div class="col-xl-12 col-lg-12 col-sm-12"> -->
                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label for="exampleFormControlInput1" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mã sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="code" placeholder="" v-model="product.code" :class="{ error: codeError.status, success: codeSuccess.status }" />
                                <p class="text-error" v-if="codeError.status">{{ codeError.text }}</p>
                                <p class="success-text" v-if="codeSuccess.status">{{ codeSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="product.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Số tháng bảo hành</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="number" class="form-control" id="warrantyMonth" placeholder="" v-model="product.warrantyMonth" :class="{ error: warrantyMonthError.status, success: warrantyMonthSuccess.status }">
                                <p class="text-error" v-if="warrantyMonthError.status">{{ warrantyMonthError.text }}</p>
                                <p class="success-text" v-if="warrantyMonthSuccess.status">{{ warrantyMonthSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <fieldset class="form-group mb-4">
                            <div class="row">
                                <label class="col-form-label col-xl-6 col-sm-6 col-sm-6 pt-0">Có màu sắc hoặc kích thước không?</label>
                                <div class="col-xl-6 col-lg-6 col-sm-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="product.option" :value="true" id="option" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Có
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" v-model="product.option" :value="false" id="status" style="width: 16px; height: 16px" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Không
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                    </div>

                    <div class="col-xl-6 col-lg-6 col-sm-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Giá</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <input type="number" class="form-control" id="price" placeholder="" v-model="product.price" :class="{ error: priceError.status, success: priceSuccess.status }">
                                        <p class="text-error" v-if="priceError.status">{{ priceError.text }}</p>
                                        <p class="success-text" v-if="priceSuccess.status">{{ priceSuccess.text }}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Danh mục</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control basic" name="" id="categoryId" v-model="product.categoryId">
                                            <option value="">Chọn</option>
                                            <option v-for="item in categorys" :key="item.id" :selected="product.categoryId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Quà tặng</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control basic" name="" id="giftId" v-model="product.giftId">
                                            <option value="">Chọn</option>
                                            <option v-for="item in gifts" :key="item.id" :selected="product.giftId === item.id" v-bind:value="item.id">{{
                                                        item.name
                                                    }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="discountType" class="col-xl-12 col-sm-12 col-form-label">Loại giảm giá</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <select class="form-control" id="discountType" v-model="discountType">
                                            <option value="amount">Số tiền</option>
                                            <option value="percentage">Phần trăm</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="discountValue" class="col-xl-12 col-sm-12 col-form-label">Giá khuyến mại hoặc phần trăm</label>
                                    <div class="col-xl-12 col-lg-12 col-sm-12">
                                        <input type="number" class="form-control" id="discountValue" placeholder="" v-model="discountValue" :class="{ error: salePriceError.status, success: salePriceSuccess.status }">
                                        <p class="text-error" v-if="salePriceError.status">{{ salePriceError.text }}</p>
                                        <p class="success-text" v-if="salePriceSuccess.status">{{ salePriceSuccess.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" ref="file" name="file" multiple="multiple" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10">
                                <img v-if="url" :src="url" style="width: 300px; height: 250px; margin-left: 37%;margin-top: -20%;" />
                            </div>
                        </div>

                    </div>

                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tiêu đề Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="titleSeo" placeholder="" v-model="product.titleSeo" :class="{ error: titleSeoError.status, success: titleSeoSuccess.status }">
                                <p class="text-error" v-if="titleSeoError.status">{{ titleSeoError.text }}</p>
                                <p class="success-text" v-if="titleSeoSuccess.status">{{ titleSeoSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Key Words</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <input type="text" class="form-control" id="metaKeyWords" placeholder="" v-model="product.metaKeyWords" :class="{ error: metaKeyWordsError.status, success: metaKeyWordsSuccess.status }">
                                <p class="text-error" v-if="metaKeyWordsError.status">{{ metaKeyWordsError.text }}</p>
                                <p class="success-text" v-if="metaKeyWordsSuccess.status">{{ metaKeyWordsSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả Seo</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <textarea name="" id="metaDescription" style="width: 100%;" cols="30" rows="10" v-model="product.metaDescription" :class="{ error: metaDescriptionError.status, success: metaDescriptionSuccess.status }"></textarea>
                                <p class="text-error" v-if="metaDescriptionError.status">{{ metaDescriptionError.text }}</p>
                                <p class="success-text" v-if="metaDescriptionSuccess.status">{{ metaDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Mô tả nhỏ</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="product.titleDescription" :class="{ error: titleDescriptionError.status, success: titleDescriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="titleDescriptionError.status">{{ titleDescriptionError.text }}</p>
                                <p class="success-text" v-if="titleDescriptionSuccess.status">{{ titleDescriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Mô tả</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="product.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Hướng dẫn sử dụng</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="product.information" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="hPassword" class="col-xl-12 col-sm-12 col-sm-12 col-form-label">Thông số kĩ thuật</label>
                            <div class="col-xl-12 col-lg-12 col-sm-12">
                                <ckeditor :editor="editor" @ready="onReady" :config="editorConfig" v-model="product.instruct" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></ckeditor>

                                <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                                <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-10">
                            <button type="submit" class="btn btn-primary mt-3" style="width: 100px;">Thêm mới</button>
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </form>
        </div>
    </div>
</div>
</template>

<style>
.ck-editor__editable_inline {
    min-height: 300px;
}
</style>

<script>
import ProductService from '@/services/ProductService';
import CategoryProductService from '@/services/CategoryProductService';
import GiftService from '@/services/GiftService';
import UploadService from "@/services/UploadService";
import {
    ClassicEditor
} from '@ckeditor/ckeditor5-editor-classic';

import {
    Alignment
} from '@ckeditor/ckeditor5-alignment'; // Importing the package.
import {
    Autoformat
} from '@ckeditor/ckeditor5-autoformat';
import {
    BlockQuote
} from '@ckeditor/ckeditor5-block-quote';
import {
    CloudServices
} from '@ckeditor/ckeditor5-cloud-services';
import {
    Essentials
} from '@ckeditor/ckeditor5-essentials';
import {
    Heading
} from '@ckeditor/ckeditor5-heading';
import {
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
    PictureEditing
} from '@ckeditor/ckeditor5-image';
import {
    Indent
} from '@ckeditor/ckeditor5-indent';
import {
    Link,
    LinkImage
} from '@ckeditor/ckeditor5-link';
import {
    List
} from '@ckeditor/ckeditor5-list';
import {
    MediaEmbed
} from '@ckeditor/ckeditor5-media-embed';
import {
    Paragraph
} from '@ckeditor/ckeditor5-paragraph';
import {
    PasteFromOffice
} from '@ckeditor/ckeditor5-paste-from-office';
import {
    Table,
    TableToolbar
} from '@ckeditor/ckeditor5-table';
import {
    TextTransformation
} from '@ckeditor/ckeditor5-typing';
import {
    SourceEditing
} from '@ckeditor/ckeditor5-source-editing';
import {
    GeneralHtmlSupport
} from '@ckeditor/ckeditor5-html-support';
import {
    CKFinder
} from '@ckeditor/ckeditor5-ckfinder';
import {
    UploadAdapter
} from '@ckeditor/ckeditor5-adapter-ckfinder';
import {
    Base64UploadAdapter,
    SimpleUploadAdapter
} from '@ckeditor/ckeditor5-upload';
import {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript
} from '@ckeditor/ckeditor5-basic-styles';
import {
    FontColor,
    FontBackgroundColor,
    FontFamily,
    FontSize
} from '@ckeditor/ckeditor5-font';
import {
    CodeBlock
} from '@ckeditor/ckeditor5-code-block';
import {
    Highlight
} from '@ckeditor/ckeditor5-highlight';
import {
    HorizontalLine
} from '@ckeditor/ckeditor5-horizontal-line';
import {
    RemoveFormat
} from '@ckeditor/ckeditor5-remove-format';
import {
    PageBreak
} from '@ckeditor/ckeditor5-page-break';
import {
    Style
} from '@ckeditor/ckeditor5-style';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                plugins: [
                    Alignment,
                    Autoformat,
                    Heading,
                    Highlight,
                    CodeBlock,
                    BlockQuote,
                    CloudServices,
                    Essentials,
                    Bold,
                    Italic,
                    PageBreak,
                    Underline, Strikethrough, Subscript, Superscript,
                    FontColor, FontBackgroundColor, FontFamily, FontSize,
                    Link,
                    Style,
                    ImageInsert,
                    Paragraph,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    LinkImage,
                    Table,
                    TableToolbar,
                    SourceEditing,
                    RemoveFormat,
                    GeneralHtmlSupport,
                    CKFinder,
                    HorizontalLine,
                    Base64UploadAdapter,
                    SimpleUploadAdapter,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    UploadAdapter,
                    Indent,
                    List,
                    MediaEmbed,
                    PasteFromOffice,
                    TextTransformation,
                    PictureEditing
                ],
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'ckfinder',
                        'insertImage',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo',
                        'alignment',
                        'fontBackgroundColor',
                        'fontColor',
                        'fontFamily',
                        'fontSize',
                        'highlight',
                        'horizontalLine',
                        'pageBreak',
                        'removeFormat',
                        'strikethrough',
                        'underline',
                        'style',
                        'sourceEditing'
                    ]
                },
                ckfinder: {
                    uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
                    filebrowserBrowseUrl: '/ckfinder/browser',
                    filebrowserImageBrowseUrl: '/ckfinder/browser?type=Images',
                    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
                    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images'
                },
                heading: {
                    options: [{
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },

                language: 'vi',
                highlight: {
                    options: [{
                            model: 'greenMarker',
                            class: 'marker-green',
                            title: 'Green marker',
                            color: 'rgb(25, 156, 25)',
                            type: 'marker'
                        },
                        {
                            model: 'yellowMarker',
                            class: 'marker-yellow',
                            title: 'Yellow marker',
                            color: '#cac407',
                            type: 'marker'
                        },
                        {
                            model: 'redPen',
                            class: 'pen-red',
                            title: 'Red pen',
                            color: 'hsl(343, 82%, 58%)',
                            type: 'pen'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        // 'imageStyle:inline',
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:alignLeft',
                        'imageStyle:alignBlockLeft',
                        'imageStyle:alignBlockRight',
                        'toggleImageCaption',
                        'linkImage'
                    ]
                },
                style: {
                    definitions: [{
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                    ]
                },
                htmlSupport: {
                    allow: [{
                        name: /^.*$/,
                        styles: true,
                        attributes: true,
                        classes: true
                    }]
                },
                // Configuration of the FontSize plugin.
                fontSize: {
                    options: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
                    ],
                    supportAllValues: true
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                fontFamily: {
                    options: [
                        'default',
                        'indieflowerregular',
                        'Arial, sans-serif',
                        'Verdana, sans-serif',
                        'Trebuchet MS',
                        'Apple Color Emoji',
                        'Segoe UI Emoji',
                        'Segoe UI Symbol',
                    ]
                },
                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [{
                        name: 'extraProvider',
                        url: /^example\.com\/media\/(\w+)/,

                    }, ]

                },
                licenseKey: ''
            },
            message: "",
            currentImage: null,
            category: null,
            categorys: null,
            gifts: null,
            url: null,
            ID: null,

            product: {
                id: null,
                code: "",
                name: "",
                warrantyMonth: "",
                description: "",
                categoryName: "",
                giftName: "",
                price: "",
                salePrice: "",
                categoryId: "",
                giftId: "",
                images: "",
                titleDescription: "",
                information: "",
                instruct: "",
                titleSeo: "",
                metaKeyWords: "",
                metaDescription: "",
                accountEdit: "null",
                start: new Date('0001-01-01 00:00:00'),
                ends: new Date('0001-01-01 00:00:00'),
                status: true,
                option: true,
                jobId: 0,
                scheduleStatus: false
            },
            discountType: 'amount', // Hoặc 'percentage'
            discountValue: 0,
            codeError: {
                text: "",
                status: false,
            },
            codeSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            warrantyMonthError: {
                text: "",
                status: false,
            },
            warrantyMonthSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },
            salePriceError: {
                text: "",
                status: false,
            },
            salePriceSuccess: {
                text: "",
                status: false,
            },
            priceError: {
                text: "",
                status: false,
            },
            priceSuccess: {
                text: "",
                status: false,
            },
            categoryIdError: {
                text: "",
                status: false,
            },
            categoryIdSuccess: {
                text: "",
                status: false,
            },
            imagesError: {
                text: "",
                status: false,
            },
            imagesSuccess: {
                text: "",
                status: false,
            },

            titleSeoError: {
                text: "",
                status: false,
            },
            titleSeoSuccess: {
                text: "",
                status: false,
            },

            metaKeyWordsError: {
                text: "",
                status: false,
            },
            metaKeyWordsSuccess: {
                text: "",
                status: false,
            },

            metaDescriptionError: {
                text: "",
                status: false,
            },
            metaDescriptionSuccess: {
                text: "",
                status: false,
            },
            titleDescriptionError: {
                text: "",
                status: false,
            },
            titleDescriptionSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {

        CategoryProductService.getAll()
            .then((res) => {
                this.categorys = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
        GiftService.getAll()
            .then((res) => {
                this.gifts = res.data;
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {

            })
    },
    computed: {
        calculatedSalePrice() {
            if (this.discountType === 'amount') {
                return this.discountValue;
            } else if (this.discountType === 'percentage') {
                return this.product.price - (this.product.price * (this.discountValue / 100));
            }
            return this.product.price;
        }
    },
    watch: {
        calculatedSalePrice(newVal) {
            this.product.salePrice = newVal > 0 ? newVal : 0;
        }
    },
    methods: {
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // this.product.images = this.$refs.file.files.item(0).name;
        },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        onSubmitForm() {
            if (this.product.code.length == 0) {
                this.codeError = {
                    text: "Code cannot be empty",
                    status: true
                };
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.product.code.length < 4) {
                this.codeError = {
                    text: "Code must contain 4 characters",
                    status: true
                }
                this.codeSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.product.code.length >= 4) {
                this.codeSuccess = {
                    text: "Success!",
                    status: true
                }
                this.codeError = {
                    text: "",
                    status: false
                }
            } else {
                this.codeSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.product.name.length == 0) {
                this.nameError = {
                    text: "NameProduct cannot be empty!",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.product.name.length < 6 || this.product.name.length > 250) {
                this.nameError = {
                    text: "NameProduct must be between 6 and 250 characters",
                    status: true
                };
                this.nameSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.product.name.length > 6 || this.product.name.length < 250) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.product.salePrice.length == 0) {
                this.salePriceError = {
                    text: "SalePrice cannot be empty",
                    status: true
                };
                this.salePriceSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.product.salePrice.length > 0) {
                this.salePriceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.salePriceError = {
                    text: "",
                    status: false
                }
            } else {
                this.salePriceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.product.description.length == 0) {
                this.descriptionError = {
                    text: "Description cannot be empty",
                    status: true
                };
                this.descriptionSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.product.description.length > 0) {
                this.descriptionSuccess = {
                    text: "Success!",
                    status: true
                }
                this.descriptionError = {
                    text: "",
                    status: false
                }
            } else {
                this.descriptionSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.product.price.length == 0) {
                this.priceError = {
                    text: "Price cannot be empty",
                    status: true
                };
                this.priceSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.product.price.length > 0) {
                this.priceSuccess = {
                    text: "Success!",
                    status: true
                }
                this.priceError = {
                    text: "",
                    status: false
                }
            } else {
                this.priceSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.product.warrantyMonth.length == 0) {
                this.warrantyMonthError = {
                    text: "warrantyMonth cannot be empty",
                    status: true
                };
                this.warrantyMonthSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.product.warrantyMonth.length > 0) {
                this.warrantyMonthSuccess = {
                    text: "Success!",
                    status: true
                }
                this.warrantyMonthError = {
                    text: "",
                    status: false
                }
            } else {
                this.warrantyMonthSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.product.categoryId == 0) {
                this.categoryIdError = {
                    text: "Must chosse CategoryId!",
                    status: true
                };
                this.categoryIdSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.product.categoryId > 0) {
                this.categoryIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.categoryIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.categoryIdSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.product.images.length == 0) {
                this.imagesError = {
                    text: "Images cannot be empty!",
                    status: true
                };
                this.imagesSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.product.images.length > 0) {
                this.imagesSuccess = {
                    text: "Success!",
                    status: true
                }
                this.imagesError = {
                    text: "",
                    status: false
                }
            } else {
                this.imagesSuccess = {
                    text: "",
                    status: true
                }
            }

            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                if (this.codeSuccess.status == true && this.nameSuccess.status == true && this.priceSuccess.status == true && this.imagesSuccess.status == true && this.warrantyMonthSuccess.status == true) {
                    UploadService.upload(this.currentImage)
                        .then((response) => {
                            this.message = response.data.message;
                            this.product.images = response.data.savedFileNames[0];
                            return ProductService.create(this.product);
                        })
                        .then((res) => {
                            //Perform Success Action 
                            this.ID = res.data.id;
                            this.product.id = this.ID;
                            this.product.status = true;
                            this.product.categoryName = res.data.categoryName;

                            console.log(this.product);

                            this.$emit("ShowData", this.product);
                            createToast({
                                title: 'Thêm mới thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch((err) => {
                            this.message = "Unable to load image  ! " + err;
                            this.currentImage = undefined;
                            console.log(err);
                            createToast({
                                title: 'Thêm mới thất bại',
                            }, {
                                position: 'top-right',
                                type: 'danger',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        });
                } else {
                    createToast({
                        title: 'Thêm mới thất bại',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
                createToast({
                    title: 'Bạn không có quyền này',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        }
    }
}
</script>
