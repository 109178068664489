<template>
<div class="card card-warning mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Chỉnh sửa bảo hành</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitEditForm" v-if="warrantys">
                <input type="hidden" v-model="warrantys.id">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên khách hàng</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="warrantys.name" :class="{ error: nameError.status, success: nameSuccess.status }" />
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="exampleFormControlInput1" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Email</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="warrantys.email" :class="{ error: emailError.status, success: emailSuccess.status }" />
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Số điện thoại</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="warrantys.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Tên sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productName" placeholder="" v-model="warrantys.productName" :class="{ error: productNameError.status, success: productNameSuccess.status }">
                                <p class="text-error" v-if="productNameError.status">{{ productNameError.text }}</p>
                                <p class="success-text" v-if="productNameSuccess.status">{{ productNameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Đại lý</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="storeCode" placeholder="" v-model="warrantys.storeCode" :class="{ error: storeCodeError.status, success: storeCodeSuccess.status }">
                                <p class="text-error" v-if="storeCodeError.status">{{ storeCodeError.text }}</p>
                                <p class="success-text" v-if="storeCodeSuccess.status">{{ storeCodeSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Seri sản phẩm</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="productSeri" placeholder="" v-model="warrantys.productSeri" :class="{ error: productSeriError.status, success: productSeriSuccess.status }">
                                <p class="text-error" v-if="productSeriError.status">{{ productSeriError.text }}</p>
                                <p class="success-text" v-if="productSeriSuccess.status">{{ productSeriSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày bắt đầu bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="startDate" placeholder="" v-model="warrantys.startDate" :class="{ error: startDateError.status, success: startDateSuccess.status }">
                                <p class="text-error" v-if="startDateError.status">{{ startDateError.text }}</p>
                                <p class="success-text" v-if="startDateSuccess.status">{{ startDateSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ngày hết hạn bảo hành</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="datetime-local" class="form-control" id="modifiedDate" placeholder="" v-model="warrantys.modifiedDate" :class="{ error: modifiedDateError.status, success: modifiedDateSuccess.status }">
                                <p class="text-error" v-if="modifiedDateError.status">{{ modifiedDateError.text }}</p>
                                <p class="success-text" v-if="modifiedDateSuccess.status">{{ modifiedDateSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row p-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
                            <span class="text-danger"><i>Chỉ gửi mail xác nhận khi thông tin bảo hành chưa chính xác!</i></span>
                            <span class="text-danger"><i>(Chỉnh sửa thông tin chính xác rồi ấn gửi mail!)</i></span>
                            <div class="col-sm-10">
                                <button type="button" @click="onConfirm()" class="btn btn-info mt-3">Gửi mail xác nhận BH</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Ảnh</label>
                            <div class="col-4" style="margin-left: 20px;">
                                <p class="btn btn-success btn-sm" @click="$refs.file.click()">
                                    Chọn file
                                </p>
                            </div>
                            <div class="col-8">
                                <label class="btn btn-default p-0">
                                    <input type="file" accept="image/*" name="file" ref="file" @change="selectImage" :hidden="true" />
                                </label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-sm-10" style="cursor: pointer;">
                                <img :src="'https://spectrababy.com.vn/dataApi/images/' + warrantys.image" style="width: 100%; height: 100%;" v-if="ishowImage == false" data-toggle="modal" data-target="#modal-lg" />
                                <img v-bind:src="url" style="width: 100%; height: 100%;" v-if="ishowImage == true" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group mb-4">
                        <label for="hPassword" class="col-xl-3 col-sm-3 col-sm-3 col-form-label">Ghi chú</label>
                        <div class="col-xl-12 col-lg-12 col-sm-12">
                            <textarea name="" id="description" style="width: 100%;" cols="30" rows="10" v-model="warrantys.description" :class="{ error: descriptionError.status, success: descriptionSuccess.status }"></textarea>
                            <p class="text-error" v-if="descriptionError.status">{{ descriptionError.text }}</p>
                            <p class="success-text" v-if="descriptionSuccess.status">{{ descriptionSuccess.text }}
                            </p>
                        </div>
                    </div>
                </div>
                <fieldset class="form-group mb-4">
                    <div class="row">
                        <label class="col-form-label col-xl-2 col-sm-3 col-sm-2 pt-0">Trạng thái</label>
                        <div class="col-xl-10 col-lg-9 col-sm-10">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="warrantys.status" :value="true" :checked="warrantys.status === true" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckDefault"> Hoạt động
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" v-model="warrantys.status" :value="false" :checked="warrantys.status === false" id="status" style="width: 16px;height: 16px;" />
                                <label class="form-check-label" for="flexCheckChecked"> Không hoạt động </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="modal fade" id="modal-lg" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <img :src="'https://spectrababy.com.vn/dataApi/images/' + warrantys.image" style="width: 100%; height: 100%;" alt="" srcset="">
                            </div>
                            <div class="modal-footer justify-content-between">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Đóng</button>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Xác nhận sửa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import WarrantyService from '@/services/WarrantyService';
import UploadService from "@/services/UploadService";
import moment from 'moment'
import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    props: ['warranty'],

    data() {

        return {
            warrantys: this.warranty,
            url: null,
            a: null,
            ishowImage: false,
            old: "https://spectrababy.com.vn/dataApi/images/" + this.warranty.image,
            currentImage: undefined,
            currentSerial: this.warranty.productSeri,
            message: "",
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            productNameError: {
                text: "",
                status: false,
            },
            productNameSuccess: {
                text: "",
                status: false,
            },
            descriptionError: {
                text: "",
                status: false,
            },
            descriptionSuccess: {
                text: "",
                status: false,
            },

            productSeriError: {
                text: "",
                status: false,
            },
            productSeriSuccess: {
                text: "",
                status: false,
            },
            startDateError: {
                text: "",
                status: false,
            },
            startDateSuccess: {
                text: "",
                status: false,
            },
            modifiedDateError: {
                text: "",
                status: false,
            },
            modifiedDateSuccess: {
                text: "",
                status: false,
            },
            storeCodeError: {
                text: "",
                status: false,
            },
            storeCodeSuccess: {
                text: "",
                status: false,
            },
        }

    },
    mounted() {
        WarrantyService.getAll().then((res) => {
            this.a = res.data
        })
    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY - h:mm:ss a')
            }
        },
        selectImage() {
            this.currentImage = this.$refs.file.files.item(0);
            this.url = URL.createObjectURL(this.currentImage);
            // this.warrantys.image = this.$refs.file.files.item(0).name;
            this.ishowImage = true;
        },
        uploadNewFile() {
            if (this.currentImage) { // Kiểm tra xem có chỉnh sửa file ảnh không
                // Upload file mới
                UploadService.upload(this.currentImage)
                    .then((response) => {
                        this.message = response.data.message;

                        // Cập nhật imageName với tên file đã lưu (tên file duy nhất)
                        this.warrantys.image = response.data.savedFileNames[0]; // Giả sử bạn chỉ tải lên một file

                        // Sau đó lưu thông tin sản phẩm vào database
                        return WarrantyService.update(this.warrantys);
                    })
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            } else {
                // Nếu không chỉnh sửa file ảnh, chỉ cần cập nhật thông tin sản phẩm
                WarrantyService.update(this.warrantys)
                    .then((res) => {
                        this.handleSuccessResponse(res);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }

            this.$emit("ShowData", this.warrantys);
        },

        handleSuccessResponse(res) {
            // Thực hiện hành động khi thành công
            this.warrantys = res.data;
            this.$emit("ShowEditData", this.warrantys);

            // Gửi dữ liệu hình ảnh lên để hiển thị trong component cha (nếu cần)
            this.$emit("ShowData", this.warrantys);

            // Hiển thị thông báo thành công
            createToast({
                title: 'Chỉnh sửa thành công',
            }, {
                position: 'top-right',
                type: 'success',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },

        handleError(error) {
            console.log(error);
            this.message = "Đã xảy ra lỗi khi sửa thông tin sản phẩm.";
            createToast({
                title: 'Chỉnh sửa thất bại',
            }, {
                position: 'top-right',
                type: 'danger',
                transition: 'zoom',
                showIcon: true,
                showCloseButton: false,
            });
        },
        onSubmitEditForm() {
            var seriSame = false;
            for (let i = 0; i < this.a.length; i++) {
                const element = this.a[i];
                // Bỏ qua số seri hiện tại khi kiểm tra
                if (this.warrantys.productSeri !== this.currentSerial && this.warrantys.productSeri == element.productSeri) {
                    seriSame = true;
                    break;
                }
            }
            if (this.warrantys.productName.length == 0) {
                this.productNameError = {
                    text: "productName cannot be empty",
                    status: true
                };
                this.productNameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warrantys.productName.length > 0) {
                this.productNameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productNameError = {
                    text: "",
                    status: false
                }
            } else {
                this.productNameSuccess = {
                    text: "",
                    status: true
                }
            }

            if (this.warrantys.productSeri.length == 0) {
                this.productSeriError = {
                    text: "productSeri cannot be empty",
                    status: true
                };
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (seriSame) {
                createToast({
                    title: 'Seri sản phẩm này đã được bảo hành',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
                this.productSeriError = {
                    text: "Seri sản phẩm này đã được bảo hành !",
                    status: true
                }
                this.productSeriSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.warrantys.productSeri.length > 0) {
                this.productSeriSuccess = {
                    text: "Success!",
                    status: true
                }
                this.productSeriError = {
                    text: "",
                    status: false
                }
            } else {
                this.productSeriSuccess = {
                    text: "",
                    status: true
                }
            }
            if (this.productNameSuccess.status == true && this.productSeriSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.currentImage) {
                        UploadService.checkFileExists(this.warrantys.image, 'images') // Thay 'images' với thư mục con thực tế
                            .then(response => {
                                if (response.data.exists) {
                                    // Nếu file tồn tại, xóa nó
                                    return UploadService.deleteFile(this.warrantys.image, 'images');
                                } else {
                                    // Nếu file không tồn tại, tiếp tục với upload file mới
                                    return Promise.resolve();
                                }
                            })
                            .finally(() => {
                                // Tiếp tục upload file mới
                                this.uploadNewFile();
                            });
                    } else {
                        // Nếu không có file cũ để kiểm tra, tiếp tục upload file mới
                        this.uploadNewFile();
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    })
                }
            } else {
                createToast({
                    title: 'Chỉnh sửa thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onConfirm() {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                WarrantyService.Mailconfirm(this.warrantys)
                    .then((res) => {
                        console.log(res);
                        createToast({
                            title: 'Đã gửi mail xác nhận BH tới khách hàng',
                        }, {
                            position: 'top-right',
                            type: 'success',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    })
                    .catch((error) => {
                        // error.response.status Check status code
                        console.log(error);
                        createToast({
                            title: 'Đã có lỗi',
                        }, {
                            position: 'top-right',
                            type: 'danger',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    })
                    .finally(() => {
                        //Perform action in always
                    });
            }
        }
    }

}
</script>
