import http from "../http-common";
let token = sessionStorage.getItem("token");
const httpOptions = {
    headers: {
        Authorization: 'Bearer '+ token,
    },
}
class OrderDetailService {
    getAll() {
        return http.get("/orderdetail",httpOptions);
    }
    get(id) {
        return http.get(`/orderdetail/${id}`,httpOptions);
    }
    create(data) {
        return http.post("/orderdetail", data,httpOptions);
    }
    sendmail(data) {
        return http.post("/OrderDetail/SendEmail", data,httpOptions);
    }
    update(data) {
        return http.post(`/orderdetail/${data.id}`, data,httpOptions);
    }
    delete(id) {
        return http.delete(`/orderdetail/${id}`,httpOptions);
    }
}
export default new OrderDetailService();