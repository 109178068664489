<template>
<div class="card-header">
    <h5>Quản lý on/off đường link website</h5>
</div>
<!--  BEGIN CONTENT AREA  -->

<div class="card-body">
    <div class="layout-px-spacing">

        <div class="row layout-top-spacing">
            <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">

                <div class="statbox widget box box-shadow" v-if="isShowAdd == false">
                    <div class="row" style="display: flex;">
                        <div class="col-md-6">
                            <a v-on:click.prevent="onAdd" class="btn btn-success mb-3">Thêm Mới +</a>
                        </div>
                    </div>
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Tên đường link</th>
                                        <th>Components</th>
                                        <th>ON/OFF</th>
                                        <th>Chức năng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in paginated" :key="item.id">
                                        <td class="text-center">
                                            {{ item.path }}
                                        </td>
                                        <td>{{ item.component }}</td>
                                        <td>
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" :checked="item.isActive === true" :id="'customSwitch'+item.id" @change="toggleActive(item)">
                                                <label class="custom-control-label" :for="'customSwitch'+item.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" data-toggle="tooltip" class="btn btn-danger" v-on:click.stop.prevent="onDelete(item)" data-placement="top" style="padding: 12px" title="Xóa" data-original-title="Delete"><i class="fa-solid fa-trash fa-lg"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="column is-12">
                            <div class="example-six">
                                <vue-awesome-paginate :total-items="resultCount" v-model="currentPage" :items-per-page="pageSize" :max-pages-shown="5" v-on:click="onClickHandler">
                                    <template #prev-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>

                                    <template #next-button>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="12" height="12" viewBox="0 0 24 24" style="vertical-align: inherit;">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                                            </svg>
                                        </span>
                                    </template>
                                </vue-awesome-paginate>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="" v-if="isShowAdd == true" v-on:click.prevent="back_to"><svg xmlns="http://www.w3.org/2000/svg" width="16" style="width: 32px; height: 32px" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg></a>
                <RoutesAdd :routes="getShowAdd" v-if="isShowAdd == true" @ShowData="getData($event)" />
            </div>
        </div>
    </div>
</div>
</template>

<style>
.example-six .pagination-container {
    column-gap: 10px;
    align-items: center;
}

.example-six .paginate-buttons {
    height: 35px;
    width: 35px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: black;
    border: 1px solid;
}

.example-six .back-button,
.example-six .next-button {
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 40px;
    width: 40px;
}

.example-six .active-page {
    background-color: #20c997;
    color: white;
}

.example-six .paginate-buttons:hover {
    background-color: #f5f5f5;
}

.example-six .active-page:hover {
    background-color: #20c997;
}

.example-six .back-button svg {
    transform: rotate(180deg) translateY(-2px);
}

.example-six .next-button svg {
    transform: translateY(2px);
}

.example-six .back-button:hover,
.example-six .next-button:hover {
    background-color: rgb(45, 45, 45);
}

.example-six .back-button:active,
.example-six .next-button:active {
    background-color: rgb(85, 85, 85);
}

.custom-switch .custom-control-label::before {
    width: 44px !important;
    height: 27px !important;
    border-radius: 20px !important;
}

.custom-switch .custom-control-label::after {
    width: 28px !important;
    height: 23px !important;
    border-radius: 20px !important;
}
</style>

<script>
import RoutesService from "@/services/RoutesService.js";
import "vue-awesome-paginate/dist/style.css";
import RoutesAdd from "../Routes/add.vue";

import {
    ref
} from "vue";
import {
    createToast
} from 'mosha-vue-toastify';
import moment from 'moment'
export default {
    name: "Index",
    components: {
        RoutesAdd,
    },
    data() {
        return {
            routes: null,
            isShowAdd: false,
            current: 1,
            pageSize: 5,
            isActive: false,
            getShowAdd: null,
            intervalId: null
        }
    },
    created() {
        this.fetchroutes();
        this.intervalId = setInterval(this.fetchroutes, 5000);
    },
    beforeUnmount() {
        clearInterval(this.intervalId); // Dừng interval khi component bị hủy
    },
    setup() {
        const currentPage = ref(1);
        return {
            currentPage,
        };
    },
    computed: {
        resultCount() {
            return this.routes && this.routes.length
        },
        indexStart() {
            return (this.currentPage * this.pageSize) - this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            if (this.resultCount > 5) {
                return this.routes.slice(this.indexStart, this.indexEnd);
            } else {
                return this.routes;
            }
        }

    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY')
            }
        },
        fetchroutes() {
            RoutesService.getAll()
                .then((res) => {
                    this.routes = res.data;
                    console.log(res);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    //Perform action in always
                })
        },
        toggleActive(item) {
            // Đảo ngược trạng thái `isActive` tạm thời
            const updatedItem = {
                ...item,
                isActive: !item.isActive
            };
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 1 || login.role == 2) {
                // Gửi yêu cầu cập nhật
                RoutesService.update(updatedItem)
                    .then(() => {
                        createToast({
                            title: `Cập nhật trạng thái thành công: ${updatedItem.path}`,
                        }, {
                            position: 'top-right',
                            type: 'success',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                        // Cập nhật trạng thái mới vào giao diện nếu thành công
                        item.isActive = updatedItem.isActive;
                    })
                    .catch((error) => {
                        createToast({
                            title: "Lỗi khi cập nhật trạng thái:",
                            error,
                        }, {
                            position: 'top-right',
                            type: 'error',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        })
                    });
            } else {
                createToast({
                    title: "Bạn không có quyền này",
                }, {
                    position: 'top-right',
                    type: 'error',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
        onClickHandler(page) {
            this.current = page.current;
        },
        back_to() {
            this.isShowAdd = false
        },
        onAdd() {
            this.isShowAdd = true;
            this.getShowAdd = this.routes;
        },
        getData(data) {
            this.routes.push(data);
            console.log(data);
            this.isShowAdd = false;
            this.$forceUpdate();

        },
        getDeleteData(data) {
            this.routes.push(data);
            console.log(data);
            this.isShowTrash = false;
            this.$forceUpdate();

        },
        onDelete(item) {
            let login = JSON.parse(sessionStorage.getItem("user"));
            if (login.role == 2) {
                if (confirm("Are you sure you want to delete " + item.path)) {
                    console.log(item.id);
                    RoutesService.delete(item.id)
                        .then(response => {
                            console.log(response);
                            this.routes.splice(this.routes.findIndex(e => e.id == item.id), 1).push(response.data);
                            createToast({
                                title: 'Xóa thành công',
                            }, {
                                position: 'top-right',
                                type: 'success',
                                transition: 'zoom',
                                showIcon: 'true',
                                showCloseButton: 'false',
                            })
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            } else {
                alert("Bạn không có quyền sử dụng chức năng này");
            }
        }
    }

}
</script>
