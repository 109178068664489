<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới đơn hàng</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <h4>Đơn hàng</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="order.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="order.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số ĐT</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="order.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Địa chỉ</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="address" placeholder="" v-model="order.address" :class="{ error: addressError.status, success: addressSuccess.status }">
                                <p class="text-error" v-if="addressError.status">{{ addressError.text }}</p>
                                <p class="success-text" v-if="addressSuccess.status">{{ addressSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Ghi chú</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="note" placeholder="" v-model="order.note">

                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Phương thức thanh toán</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control  basic" v-model="order.paymentMethod" id="status" :class="{ error: paymentMethodError.status, success: paymentMethodSuccess.status }">
                                    <option value="" selected disabled>Chọn
                                    </option>
                                    <option value="COD">Thanh toán COD
                                    </option>
                                    <option value="Chuyển khoản">Thanh toán chuyển khoản
                                    </option>
                                </select>
                                <p class="text-error" v-if="paymentMethodError.status">{{ paymentMethodError.text }}</p>
                                <p class="success-text" v-if="paymentMethodSuccess.status">{{ paymentMethodSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Tài khoản khách hàng</label>
                            <div class="col-xl-4 col-lg-4 col-sm-4">
                                <input type="text" class="form-control" placeholder="Tìm kiếm khách hàng">
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-4">
                                <select class="form-control basic" name="" id="accountUserId" v-model="order.accountUserId" :class="{ error: accountUserIdError.status, success: accountUserIdSuccess.status }">
                                    <option value="" selected disabled>Chọn</option>
                                    <option v-for="item in account" :key="item.id" v-bind:value="item.id">
                                        {{ item.name + " " + item.phone}}
                                    </option>
                                </select>
                                <p class="text-error" v-if="accountUserIdError.status">{{ accountUserIdError.text }}</p>
                                <p class="success-text" v-if="accountUserIdSuccess.status">{{ accountUserIdSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Sản phẩm</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Sản phẩm</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="productId" v-model="orderDetails.productId" @change="fetchProductDetails">
                                    <option value="" selected disabled>Chọn</option>
                                    <option v-for="item in product" :key="item.id" v-bind:value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4" v-if="itemleght > 0">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Hậu tố</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="brand" v-model="orderDetails.brand">
                                    <option value="" disabled>Chọn</option>
                                    <option v-for="item in itempro" :key="item.id" v-bind:value="item.valueAttributeName">
                                        {{ item.valueAttributeName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Quà tặng </label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="gift" v-model="orderDetails.gift">
                                    <option value="" disabled>Chọn</option>
                                    <option v-for="item in gifts" :key="item.id" v-bind:value="item.name">{{
                                                        item.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Voucher </label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="discountVoucher" v-model="orderDetails.discountVoucher">
                                    <option value="0" selected disabled>Chọn</option>
                                    <option v-for="item in voucher" :key="item.id" v-bind:value="item.discount">{{
                                                        item.voucherCode + " " + "Discount: " + item.discount + " VNĐ"
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số lượng</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="number" class="form-control" id="quantity" placeholder="Nhập số lượng" v-model="orderDetails.quantity">
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tổng giá</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="price" disabled placeholder="Giá tổng" v-model="orderDetails.price">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-10">
                        <button type="button" class="btn btn-info mt-3 mb-3" @click="addToTempList()">+ Thêm sản phẩm</button>
                    </div>
                    <div class="col-md-12" v-if="prolist.length>0">
                        <h5>Danh sách sản phẩm</h5>
                        <div class="row mt-2">
                            <div class="card-body table-responsive">
                                <table class="table table-head-fixed text-pre-line table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Tên sản phẩm
                                            </th>
                                            <th>
                                                Quà tặng hoặc voucher
                                            </th>
                                            <th>
                                                Số lượng
                                            </th>
                                            <th>
                                                Màu hoặc kích thước
                                            </th>
                                            <th>
                                                Thương hiệu
                                            </th>
                                            <th>
                                                Giá
                                            </th>
                                            <th>
                                                Tổng
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in prolist" :key="item.id">
                                            <td>
                                                {{ index +=1 }}
                                            </td>
                                            <td>
                                                {{ item.productName }}
                                            </td>
                                            <td>
                                                {{ item.gift }} Voucher: {{item.discountVoucher}} VNĐ
                                            </td>
                                            <td>
                                                {{ item.quantity }}
                                            </td>
                                            <td>
                                                {{ item.brand }}
                                            </td>
                                            <td>
                                                Spectra
                                            </td>
                                            <td>
                                                {{ formatPrice(item.price ) }} VNĐ
                                            </td>
                                            <td>
                                                {{ formatPrice((item.price * item.quantity) - item.discountVoucher ) }} VNĐ
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger" @click="removeproductList(i)">Xóa</button>
                                            </td>
                                        </tr>
                                        <tr style="text-align: right;">
                                            <td colspan="6"> <b>Phí ship:</b> 0 VND</td>
                                        </tr>
                                        <tr style="text-align: right;">
                                            <td colspan="6"> <b>Tổng tiền:</b>{{ formatPrice(totalPrice) }} VNĐ</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới đơn hàng</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import OrderDetailService from '@/services/OrderDetailService';
import OrderServices from '@/services/OrderServices';
import ProductService from '@/services/ProductService';
import AccountUserService from "@/services/AccountUserService.js";
import GiftService from '@/services/GiftService';
import ItemService from '@/services/ItemService';
import VoucherService from '@/services/VoucherService';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            orders: null,
            url: null,
            ID: null,
            product: null,
            possible: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
            lengthOfCode: 8,
            gifts: null,
            itempro: null,
            itemleght: null,
            voucher: null,
            account: null,
            prolist: [],
            order: {
                id: null,
                code: "",
                name: "",
                email: "",
                phone: "",
                note: "",
                address: "",
                paymentMethod: "",
                totalQuantity: "",
                totalAmount: "",
                website: 2,
                accountUserId: "",
                status: 1
            },
            orderDetails: {
                id: null,
                productId: "",
                quantity: "",
                gift: "",
                brand: "",
                price: "",
                discountVoucher: 0,
                status: true,
                orderId: ""

            },
            selectedProduct: null,
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            addressError: {
                text: "",
                status: false,
            },
            addressSuccess: {
                text: "",
                status: false,
            },
            paymentMethodError: {
                text: "",
                status: false,
            },
            paymentMethodSuccess: {
                text: "",
                status: false,
            },
            accountUserIdError: {
                text: "",
                status: false,
            },
            accountUserIdSuccess: {
                text: "",
                status: false,
            }
        }

    },
    computed: {
        totalPrice() {
            return this.prolist.reduce((total, item) => {
                const itemTotal = (item.price * item.quantity) - item.discountVoucher;
                return total + itemTotal;
            }, 0);
        }
    },
    mounted() {
        this.fetchData();
        VoucherService.getAll()
            .then((res) => {
                this.voucher = res.data;
            })
            .catch((error) => {
                console.error(error);
            });
        AccountUserService.getAll()
            .then((res) => {
                this.account = res.data;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    methods: {
        makeRandom(lengthOfCode, possible) {
            let text = "";
            for (let i = 0; i < lengthOfCode; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        calculateOrderTotals() {
            this.order.totalQuantity = this.prolist.reduce((sum, product) => sum + parseInt(product.quantity || 0), 0);
            this.order.totalAmount = this.prolist.reduce((sum, product) => sum + ((parseFloat(product.price || 0) * parseInt(product.quantity || 0)) - parseFloat(product.discountVoucher || 0)), 0);
        },
        addToTempList() {
            // Kiểm tra các trường bắt buộc
            if (!this.orderDetails.productId || !this.orderDetails.quantity || !this.orderDetails.price || !this.orderDetails.gift) {
                createToast({
                    title: 'Vui lòng điền đầy đủ thông tin sản phẩm.',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                });
                return;
            }
            const selectedProduct = this.product.find(
                (p) => p.id === this.orderDetails.productId
            );
            const productName = selectedProduct ? selectedProduct.name : "Không xác định";
            // Tạo đối tượng sản phẩm mới
            const newProduct = {
                id: null,
                productId: this.orderDetails.productId,
                productName: productName,
                quantity: this.orderDetails.quantity,
                gift: this.orderDetails.gift,
                brand: this.orderDetails.brand,
                price: this.orderDetails.price,
                discountVoucher: this.orderDetails.discountVoucher,
                status: this.orderDetails.status,
                orderId: this.orderDetails.orderId,
            };

            // Thêm vào danh sách tạm thời
            this.prolist.push(newProduct);
            console.log(this.prolist)
            this.calculateOrderTotals();
            // Reset form sau khi thêm sản phẩm
            this.resetOrderDetails();
        },
        resetOrderDetails() {
            this.orderDetails = {
                id: null,
                productId: "",
                quantity: "",
                gift: "",
                brand: "",
                price: "",
                discountVoucher: 0,
                status: true,
                orderId: "",
            };
        },
        removeproductList(i) {
            this.prolist.splice(i, 1);
        },
        fetchData() {
            ProductService.getAll()
                .then((res) => {
                    this.product = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });

            GiftService.getAll()
                .then((res) => {
                    this.gifts = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchProductDetails() {
            if (!this.orderDetails.productId) return;

            this.selectedProduct = this.product.find(item => item.id === this.orderDetails.productId);
            if (this.orderDetails.quantity > 0) {
                this.updateTotalPrice();
            }
            ItemService.getproOrder(this.orderDetails.productId)
                .then((res) => {
                    this.itempro = res.data; // Cập nhật danh sách hậu tố
                    this.itemleght = this.itempro.length;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        updateTotalPrice() {
            // Tính giá tổng: Giá sản phẩm × Số lượng
            this.orderDetails.price = this.orderDetails.quantity * (this.selectedProduct ?.price || 0);
        },
        onSubmitForm() {

            if (this.order.name.length == 0) {
                this.nameError = {
                    text: "FullName cannot be empty!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.name.length < 6 || this.order.name.length > 50) {
                this.nameError = {
                    text: "FullName must be between 6 and 50 characters",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.name.length > 6 || this.order.name.length < 50) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.order.email.length == 0 || this.order.email == '') {
                this.emailError.text = "Email cannot be empty!",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;

            } else if (!regex.test(this.order.email)) {
                this.emailError.text = "Email is not in the correct format !",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;
            } else if (regex.test(this.order.email)) {
                this.emailSuccess.text = "Success!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            const regex_phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            if (this.order.phone.length == 0) {
                this.phoneError = {
                    text: "Phone cannot be empty",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex_phone.test(this.order.phone)) {
                this.phoneError.text = "Phone is not in the correct format !",
                    this.phoneError.status = true;
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.phone.length != 10) {
                this.phoneError = {
                    text: "Phone must contain 10 characters ",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.phone.length < 11 && this.order.phone.length > 9) {
                this.phoneSuccess = {
                    text: "Success!",
                    status: true
                }
                this.phoneError = {
                    text: "",
                    status: false
                }
            } else {
                this.phoneError = {
                    text: "",
                    status: false
                }
            }
            if (this.order.address.length == 0) {
                this.addressError = {
                    text: "address cannot be empty",
                    status: true
                }
                this.addressSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.address.length < 8) {
                this.addressError = {
                    text: "address must contain at least 8 characters ",
                    status: true
                }
                this.addressSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.address.length >= 8) {
                this.addressSuccess = {
                    text: "Success!",
                    status: true
                }
                this.addressError = {
                    text: "",
                    status: false
                }
            } else {
                this.addressError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.paymentMethod.length == 0) {
                this.paymentMethodError = {
                    text: "paymentMethod cannot be empty",
                    status: true
                }
                this.paymentMethodSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.order.paymentMethod.length > 0) {
                this.paymentMethodSuccess = {
                    text: "Success!",
                    status: true
                }
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            } else {
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.paymentMethod.length == 0) {
                this.paymentMethodError = {
                    text: "paymentMethod cannot be empty",
                    status: true
                }
                this.paymentMethodSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.paymentMethod.length > 0) {
                this.paymentMethodSuccess = {
                    text: "Success!",
                    status: true
                }
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            } else {
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            }

            if (!this.order.accountUserId) {
                this.accountUserIdError = {
                    text: "accountUserId cannot be empty",
                    status: true
                }
                this.accountUserIdSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.accountUserId) {
                this.accountUserIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.accountUserIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.accountUserIdError = {
                    text: "",
                    status: false
                }
            }

            if (this.nameSuccess.status == true && this.emailSuccess.status == true && this.phoneSuccess.status == true && this.addressSuccess.status == true && this.paymentMethodSuccess.status == true && this.accountUserIdSuccess.status == true) {
                let login = JSON.parse(sessionStorage.getItem("user"));
                if (login.role == 1 || login.role == 2) {
                    if (this.prolist.length != 0) {
                        this.order.code = this.makeRandom(this.lengthOfCode, this.possible);
                        OrderServices.create(this.order)
                            .then((res) => {
                                // Đơn hàng đã được thêm thành công
                                this.ID = res.data.id; // Lưu ID của đơn hàng
                                this.order.id = this.ID;
                                this.order.status = true;
                                this.$emit("ShowData", this.order);

                                // Thêm orderId vào từng sản phẩm trong prolist
                                this.prolist.forEach(product => {
                                    product.orderId = this.ID; // Gắn orderId cho từng sản phẩm
                                });

                                console.log(this.prolist);

                                // Thêm mới chi tiết đơn hàng cho từng sản phẩm
                                Promise.all(this.prolist.map((product) =>
                                    OrderDetailService.create(product)
                                    .then((detailRes) => {
                                        product.id = detailRes.data.id; // Lưu ID của chi tiết đơn hàng
                                        product.status = true;
                                        console.log(product);
                                        this.$emit("ShowData", product);
                                    })
                                    .catch((error) => {
                                        console.log("Lỗi thêm chi tiết đơn hàng:", error);
                                    })
                                )).then(() => {
                                    // Sau khi thêm tất cả chi tiết đơn hàng thành công, gửi email
                                    OrderDetailService.sendmail({
                                        accountName: res.data.name,
                                        phone: res.data.phone,
                                        address: res.data.address,
                                        payment: res.data.paymentMethod,
                                        totalPrice: res.data.totalAmount,
                                        code: res.data.code,
                                        email: res.data.email,
                                        orderDetails: this.prolist
                                    }).then((res) => {
                                        console.log("Gửi mail thành công:", res);
                                        createToast({
                                            title: 'Thêm mới và gửi mail thành công',
                                        }, {
                                            position: 'top-right',
                                            type: 'success',
                                            transition: 'zoom',
                                            showIcon: 'true',
                                            showCloseButton: 'false',
                                        });
                                    }).catch((error) => {
                                        console.log("Lỗi gửi mail:", error);
                                        createToast({
                                            title: 'Thêm mới thành công nhưng gửi mail thất bại',
                                        }, {
                                            position: 'top-right',
                                            type: 'danger',
                                            transition: 'zoom',
                                            showIcon: 'true',
                                            showCloseButton: 'false',
                                        });
                                    });
                                }).catch(() => {
                                    console.log("Lỗi khi thêm chi tiết đơn hàng.");
                                    createToast({
                                        title: 'Thêm chi tiết đơn hàng thất bại',
                                    }, {
                                        position: 'top-right',
                                        type: 'danger',
                                        transition: 'zoom',
                                        showIcon: 'true',
                                        showCloseButton: 'false',
                                    });
                                });
                            })
                            .catch((error) => {
                                // Xử lý lỗi khi thêm đơn hàng
                                console.log("Lỗi thêm đơn hàng:", error);
                                createToast({
                                    title: 'Thêm mới thất bại',
                                }, {
                                    position: 'top-right',
                                    type: 'danger',
                                    transition: 'zoom',
                                    showIcon: 'true',
                                    showCloseButton: 'false',
                                });
                            });
                    } else {
                        createToast({
                            title: 'Bạn chưa thêm sản phẩm vào đơn này',
                        }, {
                            position: 'top-right',
                            type: 'danger',
                            transition: 'zoom',
                            showIcon: 'true',
                            showCloseButton: 'false',
                        });
                    }
                } else {
                    alert("Bạn không có quyền sử dụng chức năng này");
                    createToast({
                        title: 'Bạn không có quyền này',
                    }, {
                        position: 'top-right',
                        type: 'danger',
                        transition: 'zoom',
                        showIcon: 'true',
                        showCloseButton: 'false',
                    });
                }
            }

        },
    },
    watch: {
        "orderDetails.quantity"() {
            // Cập nhật giá tổng khi số lượng thay đổi
            if (this.selectedProduct) {
                this.updateTotalPrice();
            }
        },
        "orderDetails.productId"() {
            // Khi chọn sản phẩm khác, giá tổng cũng thay đổi
            this.fetchProductDetails();
        },
    },
}
</script>
